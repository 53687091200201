<template>
  <b-card class="mt-4">
    <template v-slot:header>
      <a v-b-toggle.workExperienceBookerForm>
        <h2 class="card-title mt-1 mb-1" slot="header">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_booker.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="workExperienceBookerForm" role="tabpanel">
      <b-card-body>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_organization')"
          label-for="concert_organization"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="concert_organization"
            name="concert_organization"
            v-model="workExperience.work_experience_booker.concert_organization"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_organization')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.concert_organization.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.concert_organization.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_organization_notes')"
          label-for="concert_organization_notes"
          :feedback="feedback('concert_organization_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.concert_organization_notes, 512)"
          v-if="workExperience.work_experience_booker.concert_organization > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="concert_organization_notes"
            name="concert_organization_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_organization_notes')"
            v-model="workExperience.work_experience_booker.concert_organization_notes"
            :state="state('concert_organization_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.concert_organization_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.concert_organization_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_show')"
          label-for="concert_show"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="concert_show"
            name="concert_show"
            v-model="workExperience.work_experience_booker.concert_show"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_show')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.concert_show.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.concert_show.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_show_notes')"
          label-for="concert_show_notes"
          :feedback="feedback('concert_show_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.concert_show_notes, 512)"
          v-if="workExperience.work_experience_booker.concert_show > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="concert_show_notes"
            name="concert_show_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.concert_show_notes')"
            v-model="workExperience.work_experience_booker.concert_show_notes"
            :state="state('concert_show_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.concert_show_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.concert_show_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.booking')"
          label-for="booking"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="booking"
            name="booking"
            v-model="workExperience.work_experience_booker.booking"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.booking')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.booking.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.booking.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.booking_notes')"
          label-for="booking_notes"
          :feedback="feedback('booking_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.booking_notes, 512)"
          v-if="workExperience.work_experience_booker.booking > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="booking_notes"
            name="booking_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.booking_notes')"
            v-model="workExperience.work_experience_booker.booking_notes"
            :state="state('booking_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.booking_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.booking_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.international_show')"
          label-for="international_show"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="international_show"
            name="international_show"
            v-model="workExperience.work_experience_booker.international_show"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.international_show')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.international_show.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.international_show.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.international_show_notes')"
          label-for="international_show_notes"
          :feedback="feedback('international_show_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.international_show_notes, 512)"
          v-if="workExperience.work_experience_booker.international_show_notes > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="international_show_notes"
            name="international_show_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.international_show_notes')"
            v-model="workExperience.work_experience_booker.international_show_notes"
            :state="state('international_show_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.international_show_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.international_show_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.artists_management')"
          label-for="artists_management"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="artists_management"
            name="artists_management"
            v-model="workExperience.work_experience_booker.artists_management"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.artists_management')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.artists_management.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.artists_management.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.artists_management_notes')"
          label-for="artists_management_notes"
          :feedback="feedback('artists_management_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.artists_management_notes, 512)"
          v-if="workExperience.work_experience_booker.artists_management > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="artists_management_notes"
            name="artists_management_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.artists_management_notes')"
            v-model="workExperience.work_experience_booker.artists_management_notes"
            :state="state('artists_management_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.artists_management_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.artists_management_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.musical_edition')"
          label-for="musical_edition"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="musical_edition"
            name="musical_edition"
            v-model="workExperience.work_experience_booker.musical_edition"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.musical_edition')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.musical_edition.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.musical_edition.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.musical_edition_notes')"
          label-for="musical_edition_notes"
          :feedback="feedback('musical_edition_notes')"
          :description="textLimitCounter(workExperience.work_experience_booker.musical_edition_notes, 512)"
          v-if="workExperience.work_experience_booker.musical_edition > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_booker')"
            @change="onContextChanged('work_experience_booker')"
            id="musical_edition_notes"
            name="musical_edition_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.musical_edition_notes')"
            v-model="workExperience.work_experience_booker.musical_edition_notes"
            :state="state('musical_edition_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.musical_edition_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.musical_edition_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.production_edition')"
          label-for="production_edition"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="production_edition"
            name="production_edition"
            v-model="workExperience.work_experience_booker.production_edition"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.production_edition')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.production_edition.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.production_edition.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.database_skills')"
          label-for="database_skills"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="database_skills"
            name="database_skills"
            v-model="workExperience.work_experience_booker.database_skills"
            :options="level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.database_skills')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.database_skills.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.database_skills.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.collaboration_tools_skills')"
          label-for="collaboration_tools_skills"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="collaboration_tools_skills"
            name="collaboration_tools_skills"
            v-model="workExperience.work_experience_booker.collaboration_tools_skills"
            :options="level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.collaboration_tools_skills')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.collaboration_tools_skills.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.collaboration_tools_skills.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_booker.digital_tools_skills')"
          label-for="digital_tools_skills"
        >
          <b-form-select
            @change="onContextChanged('work_experience_booker')"
            id="digital_tools_skills"
            name="digital_tools_skills"
            v-model="workExperience.work_experience_booker.digital_tools_skills"
            :options="level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_booker.digital_tools_skills')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_booker.digital_tools_skills.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_booker.digital_tools_skills.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import { required, maxLength } from 'vuelidate/lib/validators'
import form from '../../mixins/form'
import _ from 'lodash'

export default {
  name: 'WorkExperienceBookerForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      workExperience: {
        work_experience_booker: {}
      },
      knowledge_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Pas de connaissances' },
        { value: 2, text: 'Quelques connaissances' },
        { value: 3, text: 'Bonnes connaissances' }
      ],
      level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Niveau Débutant' },
        { value: 2, text: 'Niveau intermédiaire' },
        { value: 3, text: 'Niveau avancé' },
        { value: 4, text: 'Niveau expert' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations: {
    workExperience: {
      work_experience_booker: {
        concert_organization: { required },
        concert_organization_notes: { maxLength: maxLength(512) },
        concert_show: { required },
        concert_show_notes: { maxLength: maxLength(512) },
        booking: { required },
        booking_notes: { maxLength: maxLength(512) },
        international_show: { required },
        international_show_notes: { maxLength: maxLength(512) },
        artists_management: { required },
        artists_management_notes: { maxLength: maxLength(512) },
        musical_edition: { required },
        musical_edition_notes: { maxLength: maxLength(512) },
        production_edition: { required },
        database_skills: { required },
        collaboration_tools_skills: { required },
        digital_tools_skills: { required }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.work_experience)) {
      this.workExperience = this.modelData.work_experience
      if (_.isEmpty(this.workExperience.work_experience_booker)) {
        this.workExperience.work_experience_booker = {}
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp, subprop) {
      this.onContextChanged(myProp, subprop)
    }, 200),
    onContextChanged (prop, subprop) {
      if (this.$v.workExperience[prop][subprop]) {
        this.$v.workExperience[prop][subprop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'work_experience', 'property': prop, 'value': this.workExperience[prop] })
    },
    validate () {
      this.$v.workExperience.$touch()
      var isValid = !this.$v.workExperience.$invalid
      this.$emit('on-validate', this.$v.workExperience, isValid)
      return isValid
    }
  }
}
</script>
