<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.modelData.documentData>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.documents.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="documentData" role="tabpanel">
      <b-card-body>
        <b-form-group
          :label="$t('validation.attributes.registration.documents.cv_file')"
          label-for="cv_file"
          :feedback="feedback('cv_file')"
          description="Fichier PDF de 6Mo maximum"
        >
          <div class="media">
            <div class="media-body">
              <b-form-file
                id="cv_file"
                name="cv_file"
                accept=".pdf, .PDF"
                ref="CVFileInput"
                browse-text="Téléverser"
                :placeholder="modelData.has_cv_file ? modelData.cv_file_name : $t('labels.no_file_chosen')"
                v-model="docsData.cv_file"
                :state="$v.docsData.cv_file.$dirty ? !$v.docsData.cv_file.$error : null"
                v-b-tooltip.hover
                :title="$t('labels.descriptions.allowed_pdf_type')"
                @change="cvFileChanged($event)"
              ></b-form-file>
              <a href="#" class="d-block mt-1" v-if="docsData.has_cv_file || docsData.cv_file" @click.prevent="deleteCVFile">{{ $t('labels.delete_file') }}</a>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.cv_file.$dirty && !$v.docsData.cv_file.required">
            Ce champs est requis
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.cv_file.$dirty && !$v.docsData.cv_file.fileSizeValidation">
            La taille du fichier est supérieure à la limite de 6Mo
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('validation.attributes.registration.documents.motivation_letter_file')"
          label-for="motivation_letter"
          :feedback="feedback('motivation_letter_file')"
          description="Fichier PDF de 6Mo maximum"
        >
          <div class="media">
            <div class="media-body">
              <b-form-file
                id="motivation_letter_file"
                name="motivation_letter_file"
                ref="motivationLetterFileInput"
                browse-text="Téléverser"
                :state="$v.docsData.motivation_letter_file.$dirty ? !$v.docsData.motivation_letter_file.$error : null"
                :placeholder="modelData.has_motivation_letter_file? modelData.motivation_letter_file_name : $t('labels.no_file_chosen')"
                v-model="modelData.motivation_letter_file"
                v-b-tooltip.hover
                :title="$t('labels.descriptions.allowed_pdf_type')"
                @change="motivationLetterFileChanged($event)"
              ></b-form-file>
              <a href="#" class="d-block mt-1" v-if="docsData.has_motivation_letter_file || docsData.motivation_letter_file" @click.prevent="deleteMotivationLetterFile">{{ $t('labels.delete_file') }}</a>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.motivation_letter_file.$dirty && !$v.docsData.motivation_letter_file.fileSizeValidation">
            La taille du fichier est supérieure à la limite de 6Mo
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('validation.attributes.registration.documents.diploma_file')"
          label-for="diploma_file"
          :feedback="feedback('diploma_file')"
          description="Fichier PDF de 6Mo maximum"
        >
          <div class="media">
            <div class="media-body">
              <b-form-file
                id="diploma_file"
                name="diploma_file"
                accept=".pdf, .PDF"
                ref="diplomaFileInput"
                browse-text="Téléverser"
                :placeholder="modelData.has_diploma_file ? modelData.diploma_file_name : $t('labels.no_file_chosen')"
                v-model="docsData.diploma_file"
                :state="$v.docsData.diploma_file.$dirty ? !$v.docsData.diploma_file.$error : null"
                v-b-tooltip.hover
                :title="$t('labels.descriptions.allowed_pdf_type')"
                @change="diplomaFileChanged($event)"
              ></b-form-file>
              <a href="#" class="d-block mt-1" v-if="docsData.has_diploma_file || docsData.diploma_file" @click.prevent="deleteDiplomaFile">{{ $t('labels.delete_file') }}</a>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.diploma_file.$dirty && !$v.docsData.diploma_file.required">
            Ce champs est requis
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.diploma_file.$dirty && !$v.docsData.diploma_file.fileSizeValidation">
            La taille du fichier est supérieure à la limite de 6Mo
          </div>
        </b-form-group>

        <!-- Optional Documents -->
        <b-form-group
          :label="$t('validation.attributes.registration.documents.references_file')"
          label-for="references_file"
          :feedback="feedback('references_file')"
          description="Fichier PDF de 6Mo maximum"
        >
          <div class="media">
            <div class="media-body">
              <b-form-file
                id="references_file"
                name="references_file"
                accept=".pdf, .PDF"
                ref="referencesFileInput"
                browse-text="Téléverser"
                :placeholder="modelData.has_references_file ? modelData.references_file_name : $t('labels.no_file_chosen')"
                v-model="docsData.references_file"
                :state="$v.docsData.references_file.$dirty ? !$v.docsData.references_file.$error : null"
                v-b-tooltip.hover
                :title="$t('labels.descriptions.allowed_pdf_type')"
                @change="referencesFileChanged($event)"
              ></b-form-file>
              <a href="#" class="d-block mt-1" v-if="docsData.has_references_file || docsData.references_file" @click.prevent="deleteReferencesFile">{{ $t('labels.delete_file') }}</a>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.references_file.$dirty && !$v.docsData.references_file.fileSizeValidation">
            La taille du fichier est supérieure à la limite de 6Mo
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('validation.attributes.registration.documents.extras_file')"
          label-for="extras_file"
          :feedback="feedback('extras_file')"
          description="Fichier PDF de 6Mo maximum"
        >
          <div class="media">
            <div class="media-body">
              <b-form-file
                id="extras_file"
                name="extras_file"
                accept=".pdf, .PDF"
                ref="extrasFileInput"
                browse-text="Téléverser"
                :placeholder="modelData.has_extras_file ? modelData.extras_file_name : $t('labels.no_file_chosen')"
                v-model="docsData.extras_file"
                :state="$v.docsData.extras_file.$dirty ? !$v.docsData.extras_file.$error : null"
                v-b-tooltip.hover
                :title="$t('labels.descriptions.allowed_pdf_type')"
                @change="extrasFileChanged($event)"
              ></b-form-file>
              <a href="#" class="d-block mt-1" v-if="docsData.has_extras_file || docsData.extras_file" @click.prevent="deleteExtrasFile">{{ $t('labels.delete_file') }}</a>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.docsData.extras_file.$dirty && !$v.docsData.extras_file.fileSizeValidation">
            La taille du fichier est supérieure à la limite de 6Mo
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.documents.list_documents')"
          label-for="list_documents"
          :feedback="feedback('list_documents')"
        >
          <b-form-input
            @input="debounceInput('list_documents')"
            @change="onContextChanged('list_documents')"
            id="list_documents"
            name="list_documents"
            :placeholder="$t('validation.attributes.registration.documents.list_documents')"
            v-model="docsData.list_documents"
            :state="state('list_documents')"
            :class="{ 'is-invalid': $v.docsData.list_documents.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.docsData.list_documents.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'DocumentsForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      imageFile: null,
      docsData: {}
    }
  },
  mounted () {
    this.docsData = this.modelData
  },
  validations () {
    const fileSizeValidation = (value, vm) => {
      if (!value) {
        return true
      }
      let file = value
      return (file.size < 6 * 1024 * 1024) // 6Mb
    }
    return {
      docsData: {
        cv_file: { required: requiredIf(function () { return !this.modelData.has_cv_file }), fileSizeValidation },
        motivation_letter_file: { required: requiredIf(function () { return !this.modelData.has_motivation_letter_file }), fileSizeValidation },
        diploma_file: { required: requiredIf(function () { return !this.modelData.has_diploma_file }), fileSizeValidation },
        references_file: { fileSizeValidation },
        extras_file: { fileSizeValidation },
        list_documents: { required: requiredIf(function () { return !this.modelData.list_documents }) }
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp) {
      this.onContextChanged(myProp)
    }, 200),
    onContextChanged (myProp) {
      this.$v.docsData[myProp].$touch()
      this.$emit('context-changed', { 'relation': null, 'property': myProp, 'value': this.docsData[myProp] })
    },
    validate () {
      this.$v.docsData.$touch()
      var isValid = !this.$v.docsData.$invalid
      this.$emit('on-validate', this.$v.docsData, isValid)
      return isValid
    },
    cvFileChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.$v.docsData.cv_file.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_cv_file', 'value': true })
      this.$emit('context-changed', { 'relation': null, 'property': 'cv_file', 'value': files[0] })
    },
    motivationLetterFileChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.$v.docsData.motivation_letter_file.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_motivation_letter_file', 'value': true })
      this.$emit('context-changed', { 'relation': null, 'property': 'motivation_letter_file', 'value': files[0] })
    },
    diplomaFileChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.$v.docsData.diploma_file.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_diploma_file', 'value': true })
      this.$emit('context-changed', { 'relation': null, 'property': 'diploma_file', 'value': files[0] })
    },
    referencesFileChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.$v.docsData.references_file.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_references_file', 'value': true })
      this.$emit('context-changed', { 'relation': null, 'property': 'references_file', 'value': files[0] })
    },
    extrasFileChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.$v.docsData.extras_file.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_extras_file', 'value': true })
      this.$emit('context-changed', { 'relation': null, 'property': 'extras_file', 'value': files[0] })
    },
    deleteCVFile () {
      this.$refs.CVFileInput.reset()
      this.modelData.cv_file = null
      this.modelData.has_cv_file = false
      this.$emit('context-changed', { 'relation': null, 'property': 'has_cv_file', 'value': false })
      this.$emit('context-changed', { 'relation': null, 'property': 'cv_file', 'value': null })
    },
    deleteMotivationLetterFile () {
      this.$refs.motivationLetterFileInput.reset()
      this.modelData.motivation_letter_file = null
      this.modelData.has_motivation_letter_file = false
      this.$emit('context-changed', { 'relation': null, 'property': 'has_motivation_letter_file', 'value': false })
      this.$emit('context-changed', { 'relation': null, 'property': 'motivation_letter_file', 'value': null })
    },
    deleteDiplomaFile () {
      this.$refs.diplomaFileInput.reset()
      this.modelData.diploma_file = null
      this.modelData.has_diploma_file = false
      this.$emit('context-changed', { 'relation': null, 'property': 'has_diploma_file', 'value': false })
      this.$emit('context-changed', { 'relation': null, 'property': 'diploma_file', 'value': null })
    },
    deleteReferencesFile () {
      this.$refs.referencesFileInput.reset()
      this.modelData.references_file = null
      this.modelData.has_references_file = false
      this.$emit('context-changed', { 'relation': null, 'property': 'has_references_file', 'value': false })
      this.$emit('context-changed', { 'relation': null, 'property': 'references_file', 'value': null })
    },
    deleteExtrasFile () {
      this.$refs.extrasFileInput.reset()
      this.modelData.extras_file = null
      this.modelData.has_extras_file = false
      this.$emit('context-changed', { 'relation': null, 'property': 'has_extras_file', 'value': false })
      this.$emit('context-changed', { 'relation': null, 'property': 'extras_file', 'value': null })
    }
  }
}
</script>
