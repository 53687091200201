<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.personalData>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.personal_data.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="personalData" role="tabpanel">
      <b-card-body>
        <div class="row">
          <div class="col-md-6">
            <div class="card p-3">
              <div class="row no-gutters">
                <b-form-group
                  :feedback="feedback('featured_image')"
                  class="col-md-12 mb-0"
                >
                  <div class="media">
                    <div class="mr-3" :style="'width:150px;height:160px;background-position:center;background-size:cover;background-image:url(' + modelData.thumbnail_id_photo_path + ');'"></div>
                    <div class="media-body">
                      <label class="" for="id_photo">{{ $t('validation.attributes.registration.id_photo') }}</label>
                      <b-form-file
                        @change="idPhotoChanged($event)"
                        id="id_photo"
                        name="id_photo"
                        accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                        ref="idPhotoInput"
                        :placeholder="$t('labels.upload_image')"
                        v-model="modelData.id_photo"
                        :state="$v.modelData.id_photo.$dirty ? !$v.modelData.id_photo.$error : null"
                        v-b-tooltip.hover
                        :title="$t('labels.descriptions.allowed_image_types')"
                      ></b-form-file>
                      <a href="#" class="d-block mt-1" v-if="modelData.has_id_photo || modelData.id_photo" @click.prevent="deleteIdPhoto">{{ $t('labels.delete_image') }}</a>
                      <div class="invalid-feedback d-block" v-if="$v.modelData.id_photo.$dirty && !$v.modelData.id_photo.required">
                        Ce champs est requis
                      </div>
                      <div class="invalid-feedback d-block" v-if="$v.modelData.id_photo.$dirty && !$v.modelData.id_photo.fileSizeValidation">
                        La taille du fichier est supérieure à la limite de 2Mo
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="row no-gutters">

              <b-form-group class="mb-2">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="personalData.title"
                  :options="title_options"
                  :state="$v.personalData.title.$dirty ? !$v.personalData.title.$error : null"
                  name="title_options"
                >
                  <b-form-invalid-feedback :state="!$v.personalData.title.$error" :class="$v.personalData.title.$error ? 'custom-inline-feedback' : ''">Ce champs est requis</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                :label="$t('validation.attributes.first_name')"
                label-for="firstname"
                :feedback="feedback('firstname')"
                class="col-md-12"
              >
                <b-form-input
                  @input="debounceInput('firstname')"
                  @change="onContextChanged('firstname')"
                  id="firstname"
                  name="firstname"
                  required
                  disabled
                  :placeholder="$t('validation.attributes.first_name')"
                  v-model="personalData.firstname"
                  :state="state('firstname')"
                  :class="{ 'is-invalid': $v.personalData.firstname.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.personalData.firstname.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                :label="$t('validation.attributes.last_name')"
                label-for="lastname"
                :feedback="feedback('lastname')"
                class="col-md-12"
              >
                <b-form-input
                  @input="debounceInput('name')"
                  @change="onContextChanged('name')"
                  id="name"
                  name="name"
                  required
                  disabled
                  :placeholder="$t('validation.attributes.last_name')"
                  v-model="personalData.name"
                  :state="state('name')"
                  :class="{ 'is-invalid': $v.personalData.name.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.personalData.name.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.email')"
            label-for="email"
            class="col-md-6"
            :feedback="feedback('email')"
          >
            <b-form-input
              @input="debounceInput('email')"
              @change="onContextChanged('email')"
              id="email"
              name="email"
              type="email"
              required
              disabled
              :placeholder="$t('validation.attributes.email')"
              v-model="personalData.email"
              :state="state('email')"
              :class="{ 'is-invalid': $v.personalData.email.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.email.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.phone')"
            label-for="phone"
            class="col-md-6"
            :feedback="feedback('phone')"
          >
            <b-form-input
              @input="debounceInput('phone')"
              @change="onContextChanged('phone')"
              id="phone"
              name="phone"
              type="number"
              :placeholder="$t('validation.attributes.phone')"
              v-model="personalData.phone"
              :state="state('phone')"
              :class="{ 'is-invalid': $v.personalData.phone.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.phone.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.address')"
            label-for="address"
            class="col-md-12"
            :feedback="feedback('address')"
          >
            <b-form-input
              @input="debounceInput('address')"
              @change="onContextChanged('address')"
              id="address"
              name="address"
              required
              :placeholder="$t('validation.attributes.address')"
              v-model="personalData.address"
              :state="state('address')"
              :class="{ 'is-invalid': $v.personalData.address.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.address.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.city')"
            label-for="city"
            class="col-md-6"
            :feedback="feedback('city')"
          >
            <b-form-input
              @input="debounceInput('city')"
              @change="onContextChanged('city')"
              id="city"
              name="city"
              :placeholder="$t('validation.attributes.city')"
              v-model="personalData.city"
              :state="state('city')"
              :class="{ 'is-invalid': $v.personalData.city.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.city.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.zipcode')"
            label-for="zipcode"
            class="col-md-6"
            :feedback="feedback('zipcode')"
          >
            <b-form-input
              @input="debounceInput('zipcode')"
              @change="onContextChanged('zipcode')"
              id="zipcode"
              name="zipcode"
              :placeholder="$t('validation.attributes.zipcode')"
              v-model="personalData.zipcode"
              :state="state('zipcode')"
              :class="{ 'is-invalid': $v.personalData.zipcode.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.zipcode.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.birth_date')"
            label-for="birth_date"
            class="col-md-6"
          >
            <b-input-group>
              <p-datetimepicker
                id="birth_date"
                name="birth_date"
                :config="config"
                @input="debounceInput('birthdate')"
                v-model="personalData.birthdate"
                :class="{ 'is-invalid': $v.personalData.birthdate.$error }"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="!$v.personalData.birthdate.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.birth_city')"
            label-for="birth_city"
            class="col-md-6"
            :feedback="feedback('birth_city')"
          >
            <b-form-input
              @input="debounceInput('birth_city')"
              @change="onContextChanged('birth_city')"
              id="birth_city"
              name="birth_city"
              :placeholder="$t('validation.attributes.birth_city')"
              v-model="personalData.birth_city"
              :state="state('birth_city')"
              :class="{ 'is-invalid': $v.personalData.birth_city.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.birth_city.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.birth_zipcode')"
            label-for="birth_zipcode"
            class="col-md-6"
            :feedback="feedback('birth_zipcode')"
          >
            <b-form-input
              @input="debounceInput('birth_zipcode')"
              @change="onContextChanged('birth_zipcode')"
              id="birth_zipcode"
              name="birth_zipcode"
              :placeholder="$t('validation.attributes.birth_zipcode')"
              v-model="personalData.birth_zipcode"
              :state="state('birth_zipcode')"
              :class="{ 'is-invalid': $v.personalData.birth_zipcode.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.birth_zipcode.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.nationality')"
            label-for="nationality"
            class="col-md-6"
            :feedback="feedback('nationality')"
          >
            <b-form-input
              @input="debounceInput('nationality')"
              @change="onContextChanged('nationality')"
              id="nationality"
              name="nationality"
              :placeholder="$t('validation.attributes.nationality')"
              v-model="personalData.nationality"
              :state="state('nationality')"
              :class="{ 'is-invalid': $v.personalData.nationality.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.nationality.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.social_security_num')"
            label-for="social_security_num"
            class="col-md-6"
            description="Composé de 15 chiffres"
            :feedback="feedback('social_security_num')"
          >
            <b-form-input
              @input="debounceInput('social_security_num')"
              @change="onContextChanged('social_security_num')"
              id="social_security_num"
              name="social_security_num"
              type="number"
              :placeholder="$t('validation.attributes.social_security_num')"
              v-model="personalData.social_security_num"
              :state="state('social_security_num')"
              :class="{ 'is-invalid': $v.personalData.social_security_num.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.social_security_num.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.family_status')"
            label-for="family_status"
            class="col-md-6"
          >
            <b-form-select
              @change="onContextChanged('family_status')"
              id="family_status"
              name="family_status"
              v-model="personalData.family_status"
              :options="family_status_options"
              :placeholder="$t('validation.attributes.family_status')"
              :class="{ 'is-invalid': $v.personalData.family_status.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.personalData.family_status.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.child_nbr')"
            label-for="child_nbr"
            class="col-md-6"
            :feedback="feedback('child_nbr')"
          >
            <b-form-input
              @input="debounceInput('child_nbr')"
              @change="onContextChanged('child_nbr')"
              id="child_nbr"
              name="child_nbr"
              :placeholder="$t('validation.attributes.child_nbr')"
              v-model="personalData.child_nbr"
              :state="state('child_nbr')"
              type="number"
              :class="{ 'is-invalid': $v.personalData.child_nbr.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.personalData.child_nbr.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, requiredIf, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'PersonalDataForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      idPhoto: null,
      idPhotoURL: null,
      personalData: {},
      family_status_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Célibataire' },
        { value: 2, text: 'Marié' },
        { value: 3, text: 'PACSÉ' },
        { value: 4, text: 'Concubinage' },
        { value: 5, text: 'Veuf' }
      ],
      title_options: [
        { value: 0, text: 'M' },
        { value: 2, text: 'Mme' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations () {
    const isPhoneNumber = (value) => /^[0-9]{10}$/gi.test(value)
    const isSSN = (value) => /^[0-9]{15}$/gi.test(value)
    const fileSizeValidation = (value, vm) => {
      if (!value) {
        return true
      }
      let file = value
      return (file.size < 2 * 1024 * 1024) // 2Mb
    }
    return {
      modelData: {
        id_photo: { required: requiredIf(function () { return !this.modelData.has_id_photo }), fileSizeValidation }
      },
      personalData: {
        title: { required },
        firstname: { required },
        name: { required },
        email: { required, email },
        address: { required },
        city: { required },
        zipcode: { required },
        phone: { required, isPhoneNumber },
        birthdate: { required },
        birth_city: { required },
        birth_zipcode: { required },
        nationality: { required },
        social_security_num: { required, isSSN, minLength: minLength(15) },
        family_status: { required },
        child_nbr: { required }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (this.modelData.personal_data !== null) {
      this.personalData = this.modelData.personal_data
    } else {
      this.modelData.personal_data = this.personalData = {
        'firstname': this.modelData.registration_user.firstname,
        'name': this.modelData.registration_user.lastname,
        'email': this.modelData.registration_user.email
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp) {
      this.onContextChanged(myProp)
    }, 200),
    onContextChanged (myProp) {
      this.$v.personalData[myProp].$touch()
      this.$emit('context-changed', { 'relation': 'personal_data', 'property': myProp, 'value': this.personalData[myProp] })
    },
    validate () {
      this.$v.personalData.$touch()
      this.$v.modelData.$touch()
      var isValid = !this.$v.personalData.$invalid && !this.$v.modelData.$invalid
      this.$emit('on-validate', this.$v.personalData, isValid)
      return isValid
    },
    idPhotoChanged (event) {
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return true
      }
      this.idPhoto = files[0]
      this.$v.modelData.id_photo.$touch()
      this.$emit('context-changed', { 'relation': null, 'property': 'has_id_photo', 'value': this.idPhoto })
      this.$emit('context-changed', { 'relation': null, 'property': 'id_photo', 'value': this.idPhoto })
      this.$emit('context-changed', { 'relation': null, 'property': 'thumbnail_id_photo_path', 'value': URL.createObjectURL(this.idPhoto) })
    },
    deleteIdPhoto () {
      this.$refs.idPhotoInput.reset()
      this.idPhotoURL = false
      this.$emit('context-changed', { 'relation': null, 'property': 'thumbnail_id_photo_path', 'value': '/imagecache/idphoto/placeholder.png' })
      this.$emit('context-changed', { 'relation': null, 'property': 'id_photo', 'value': null })
      this.$emit('context-changed', { 'relation': null, 'property': 'has_id_photo', 'value': false })
    }
  }
}
</script>
