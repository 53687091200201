import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPlay,
  faPlus,
  faCheck,
  faLock,
  faSearch,
  faBars,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

import {

} from '@fortawesome/free-regular-svg-icons'

import {
  faFacebook,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHome,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPlay,
  faPlus,
  faCheck,
  faLock,
  faSearch,
  faBars,
  faInfoCircle,
  faFacebook,
  faInstagram,
  faTwitter
)

config.autoAddCss = false

Vue.component('font-awesome-icon', FontAwesomeIcon)
