<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.workExperience>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.work_experience.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="workExperience" role="tabpanel">
      <h4 class="card-title card-title ml-4 mt-4">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_paid.title') }}</h4>
      <b-card-body>
        <b-card class="paidwork">
          <b-card-body class="study" v-for="(wpaid, index) in workExperience.work_experience_paid" :key="index">
            <div class="row">
              <div class="col-12 mb-5"><h5 class="text-center">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_paid.subtitle') }} #{{ index+1 }}</h5></div>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.date_start')"
                label-for="paidwork_date_start"
              >
                <b-input-group>
                  <p-datetimepicker
                    id="paidwork_date_start"
                    name="paidwork_date_start"
                    :config="config"
                    v-model="workExperience.work_experience_paid[index].date_start"
                    @input="debounceInput('work_experience_paid', 'date_start', index)"
                    @change="onContextChanged('work_experience_paid', 'date_start', index)"
                    :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].date_start.$error }"
                  ></p-datetimepicker>
                  <b-input-group-append>
                    <b-input-group-text data-toggle>
                      <i class="fe fe-calendar"></i>
                    </b-input-group-text>
                    <b-input-group-text data-clear>
                      <i class="fe fe-x-circle"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].date_start.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.date_end')"
                label-for="paidwork_date_end"
              >
                <b-input-group>
                  <p-datetimepicker
                    id="paidwork_date_end"
                    name="paidwork_date_end"
                    :config="config"
                    v-model="workExperience.work_experience_paid[index].date_end"
                    @input="debounceInput('work_experience_paid', 'date_end', index)"
                    @change="onContextChanged('work_experience_paid', 'date_end', index)"
                    :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].date_end.$error }"
                  ></p-datetimepicker>
                  <b-input-group-append>
                    <b-input-group-text data-toggle>
                      <i class="fe fe-calendar"></i>
                    </b-input-group-text>
                    <b-input-group-text data-clear>
                      <i class="fe fe-x-circle"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].date_end.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.contract')"
                label-for="paidwork_contract"
              >
                <b-form-select
                  @input="debounceInput('work_experience_paid', 'contract', index)"
                  @change="onContextChanged('work_experience_paid', 'contract', index)"
                  id="paidwork_contract"
                  name="paidwork_contract"
                  v-model="workExperience.work_experience_paid[index].contract"
                  :options="work_contract_options"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_paid.contract')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].contract.$error }"
                ></b-form-select>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].contract.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.position')"
                label-for="paidwork_position"
                :feedback="feedback('paidwork_position')"
                :description="textLimitCounter(workExperience.work_experience_paid[index].position, 256)"
              >
                <b-form-input
                  @input="debounceInput('work_experience_paid', 'position', index)"
                  @change="onContextChanged('work_experience_paid', 'position', index)"
                  id="paidwork_position"
                  name="paidwork_position"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_paid.position')"
                  v-model="workExperience.work_experience_paid[index].position"
                  :state="state('paidwork_position')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].position.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].position.$error">
                  Ce champs est requis - 256 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.company')"
                label-for="paidwork_company"
                :feedback="feedback('paidwork_company')"
                :description="textLimitCounter(workExperience.work_experience_paid[index].company, 256)"
              >
                <b-form-input
                  @input="debounceInput('work_experience_paid', 'company', index)"
                  @change="onContextChanged('work_experience_paid', 'company', index)"
                  id="paidwork_company"
                  name="paidwork_company"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_paid.company')"
                  v-model="workExperience.work_experience_paid[index].company"
                  :state="state('paidwork_company')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].company.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].company.$error">
                  Ce champs est requis - 256 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-12"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.address')"
                label-for="paidwork_address"
                :feedback="feedback('paidwork_address')"
                :description="textLimitCounter(workExperience.work_experience_paid[index].company_address, 256)"
              >
                <b-form-input
                  @input="debounceInput('work_experience_paid', 'company_address', index)"
                  @change="onContextChanged('work_experience_paid', 'company_address', index)"
                  id="paidwork_address"
                  name="paidwork_address"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_paid.address')"
                  v-model="workExperience.work_experience_paid[index].company_address"
                  :state="state('paidwork_address')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].company_address.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].company_address.$error">
                  Ce champs est requis - 256 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-12"
                :label="$t('validation.attributes.registration.work_experience.work_experience_paid.note')"
                label-for="paidwork_note"
                :feedback="feedback('paidwork_note')"
                :description="textLimitCounter(workExperience.work_experience_paid[index].note, 512)"
              >
                <b-form-textarea
                  @input="debounceInput('work_experience_paid', 'note', index)"
                  @change="onContextChanged('work_experience_paid', 'note', index)"
                  id="paidwork_note"
                  name="paidwork_note"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_paid.note')"
                  v-model="workExperience.work_experience_paid[index].note"
                  :state="state('paidwork_note')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_paid.$each[index].note.$error }"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_paid.$each[index].note.$error">
                  512 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="col-12 mb-5 mt-3 text-center"><div class="btn btn-warning btn-sm mx-auto text-center" @click="removePaidWork(index)"><i class="fe fe-trash"></i> &nbsp; {{ $t('buttons.registration.relations.work_experience.work_experience_paid.remove_work_experience_paid') }}</div></div>
              <div class="col"><hr></div>
            </div>
          </b-card-body>
          <b-button size="sm" class="w-100"
                    variant="dark"
                    shaded btn-ico
                    :disabled="pending"
                    @click="addPaidWork"
                    v-show="!workExperience.work_experience_paid || workExperience.work_experience_paid.length < 5"
          >
            {{ $t('buttons.registration.relations.work_experience.work_experience_paid.add_work_experience_paid') }}
          </b-button>
        </b-card>
      </b-card-body>
      <h4 class="card-title card-title ml-4 mt-4">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_music.title') }}</h4>
      <b-card-body>
        <b-card class="musicwork">
          <b-card-body class="study" v-for="(wmusic, index) in workExperience.work_experience_music" :key="index">
            <div class="row">
              <div class="col-12 mb-5"><h5 class="text-center">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_music.subtitle') }} #{{ index+1 }}</h5></div>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.date_start')"
                label-for="musicwork_date_start"
              >
                <b-input-group>
                  <p-datetimepicker
                    id="musicwork_date_start"
                    name="musicwork_date_start"
                    :config="config"
                    v-model="workExperience.work_experience_music[index].date_start"
                    @input="debounceInput('work_experience_music', 'date_start', index)"
                    @change="onContextChanged('work_experience_music', 'date_start', index)"
                    :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].date_start.$error }"
                  ></p-datetimepicker>
                  <b-input-group-append>
                    <b-input-group-text data-toggle>
                      <i class="fe fe-calendar"></i>
                    </b-input-group-text>
                    <b-input-group-text data-clear>
                      <i class="fe fe-x-circle"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].date_start.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.date_end')"
                label-for="musicwork_date_end"
              >
                <b-input-group>
                  <p-datetimepicker
                    id="musicwork_date_end"
                    name="musicwork_date_end"
                    :config="config"
                    v-model="workExperience.work_experience_music[index].date_end"
                    @input="debounceInput('work_experience_music', 'date_end', index)"
                    @change="onContextChanged('work_experience_music', 'date_end', index)"
                    :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].date_end.$error }"
                  ></p-datetimepicker>
                  <b-input-group-append>
                    <b-input-group-text data-toggle>
                      <i class="fe fe-calendar"></i>
                    </b-input-group-text>
                    <b-input-group-text data-clear>
                      <i class="fe fe-x-circle"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].date_end.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.status')"
                label-for="musicwork_status"
              >
                <b-form-select
                  id="musicwork_status"
                  name="musicwork_status"
                  v-model="workExperience.work_experience_music[index].contract"
                  :options="work_statuses_options"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_music.status')"
                  @input="debounceInput('work_experience_music', 'contract', index)"
                  @change="onContextChanged('work_experience_music', 'contract', index)"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].contract.$error }"
                ></b-form-select>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].contract.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.position')"
                label-for="musicwork_position"
                :feedback="feedback('musicwork_position')"
                :description="textLimitCounter(workExperience.work_experience_music[index].position, 256)"
              >
                <b-form-input
                  @input="debounceInput('work_experience_music', 'position', index)"
                  @change="onContextChanged('work_experience_music', 'position', index)"
                  id="musicwork_position"
                  name="musicwork_position"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_music.position')"
                  v-model="workExperience.work_experience_music[index].position"
                  :state="state('musicwork_position')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].position.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].position.$error">
                  Ce champs est requis - 256 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.organization')"
                label-for="musicwork_organization"
                :feedback="feedback('musicwork_organization')"
                :description="textLimitCounter(workExperience.work_experience_music[index].organization, 256)"
              >
                <b-form-input
                  @input="debounceInput('work_experience_music', 'organization', index)"
                  @change="onContextChanged('work_experience_music', 'organization', index)"
                  id="musicwork_organization"
                  name="musicwork_organization"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_music.organization')"
                  v-model="workExperience.work_experience_music[index].organization"
                  :state="state('musicwork_organization')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].organization.$error }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].organization.$error">
                  Ce champs est requis - 256 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-12"
                :label="$t('validation.attributes.registration.work_experience.work_experience_music.note')"
                label-for="musicwork_note"
                :feedback="feedback('musicwork_note')"
                :description="textLimitCounter(workExperience.work_experience_music[index].note, 512)"
              >
                <b-form-textarea
                  @input="debounceInput('work_experience_music', 'note', index)"
                  @change="onContextChanged('work_experience_music', 'note', index)"
                  id="musicwork_note"
                  name="musicwork_note"
                  :placeholder="$t('validation.attributes.registration.work_experience.work_experience_music.note')"
                  v-model="workExperience.work_experience_music[index].note"
                  :state="state('musicwork_note')"
                  :class="{ 'is-invalid': $v.workExperience.work_experience_music.$each[index].note.$error }"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!$v.workExperience.work_experience_music.$each[index].note.$error">
                  512 caractères maximum
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="col-12 mb-5 mt-3 text-center"><div class="btn btn-warning btn-sm mx-auto text-center" @click="removeMusicWork(index)"><i class="fe fe-trash"></i> &nbsp; {{ $t('buttons.registration.relations.work_experience.work_experience_music.remove_work_experience_music') }}</div></div>
              <div class="col"><hr></div>
            </div>
          </b-card-body>
          <b-button size="sm" class="w-100"
                    :disabled="pending"
                    @click="addMusicWork"
                    variant="dark"
                    v-show="!workExperience.work_experience_music || workExperience.work_experience_music.length < 5"
          >
            {{ $t('buttons.registration.relations.work_experience.work_experience_music.add_work_experience_music') }}
          </b-button>
        </b-card>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'WorkExperienceForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      workExperience: {},
      work_contract_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'CDI' },
        { value: 2, text: 'CDD' },
        { value: 3, text: 'Contrat Aidé' },
        { value: 4, text: 'Intermittent' },
        { value: 5, text: 'Service Civique / Autre' }
      ],
      work_statuses_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Porteur de projet' },
        { value: 2, text: 'Dirigeant' },
        { value: 3, text: 'Salarié' },
        { value: 4, text: 'Stagiaire' },
        { value: 5, text: 'Bénévole' },
        { value: 6, text: 'Membre d\'une association' },
        { value: 7, text: 'Autre' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations: {
    workExperience: {
      work_experience_paid: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(5),
        $each: {
          date_start: {
            required
          },
          date_end: {
            required
          },
          contract: {
            required
          },
          position: {
            required,
            maxLength: maxLength(256)
          },
          company: {
            required,
            maxLength: maxLength(256)
          },
          company_address: {
            required,
            maxLength: maxLength(256)
          },
          note: {
            maxLength: maxLength(512)
          }
        }
      },
      work_experience_music: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(5),
        $each: {
          date_start: {
            required
          },
          date_end: {
            required
          },
          contract: {
            required
          },
          position: {
            required,
            maxLength: maxLength(256)
          },
          organization: {
            required,
            maxLength: maxLength(256)
          },
          note: {
            maxLength: maxLength(512)
          }
        }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.work_experience)) {
      this.workExperience = this.modelData.work_experience
      if (_.isEmpty(this.workExperience.work_experience_paid) || this.workExperience.work_experience_paid.length === 0) {
        this.workExperience.work_experience_paid = [{}]
      }
      if (_.isEmpty(this.workExperience.work_experience_music) || this.workExperience.work_experience_music.length === 0) {
        this.workExperience.work_experience_music = [{}]
      }
    } else {
      if (_.isEmpty(this.modelData.work_experience)) {
        this.workExperience = { work_experience_paid: [{}], work_experience_music: [{}] }
        delete this.modelData.work_experience
      } else {
        if (_.isEmpty(this.modelData.work_experience.work_experience_paid)) {
          this.workExperience.work_experience_paid = [{}]
        }
        if (_.isEmpty(this.modelData.work_experience.work_experience_music)) {
          this.workExperience.work_experience_music = [{}]
        }
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (prop, subprop, pindex) {
      this.onContextChanged(prop, subprop, pindex)
    }, 200),
    onContextChanged (prop, subprop, pindex) {
      if (subprop && pindex !== null) {
        if (this.$v.workExperience[prop].$each[pindex][subprop]) {
          this.$v.workExperience[prop].$each[pindex][subprop].$touch()
        }
      } else {
        this.$v.workExperience[prop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'work_experience', 'property': prop, 'value': this.workExperience[prop] })
    },
    validate () {
      this.$v.workExperience.$touch()
      var isValid = !this.$v.workExperience.$invalid
      this.$emit('on-validate', this.$v.workExperience, isValid)
      return isValid
    },
    addPaidWork () {
      if (this.workExperience && this.workExperience.work_experience_paid && this.workExperience.work_experience_paid.length > 0) {
        this.workExperience.work_experience_paid.push({})
      } else {
        if (_.isEmpty(this.workExperience)) {
          this.workExperience = { work_experience_paid: [{}] }
        } else {
          this.workExperience.work_experience_paid = [{}]
        }
      }
    },
    async removePaidWork (indexb) {
      let result = await window.swal({
        title: this.$t('labels.are_you_sure'),
        text: 'Souhaitez-vous supprimer cette expérience professionnelle ?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonColor: '#dd4b39',
        confirmButtonText: this.$t('buttons.confirm')
      })

      if (result.value) {
        let items = this.workExperience.work_experience_paid.slice()
        items = items.filter((item, index) => {
          return index !== indexb
        })
        this.workExperience.work_experience_paid = items
        return true
      } else {
        return false
      }
    },
    addMusicWork () {
      if (this.workExperience && this.workExperience.work_experience_music && this.workExperience.work_experience_music.length > 0) {
        this.workExperience.work_experience_music.push({})
      } else {
        if (_.isEmpty(this.workExperience)) {
          this.workExperience = { work_experience_music: [{}] }
        } else {
          this.workExperience.work_experience_music = [{}]
        }
      }
    },
    async removeMusicWork (indexb) {
      let result = await window.swal({
        title: this.$t('labels.are_you_sure'),
        text: 'Souhaitez-vous supprimer cette expérience professionnelle ?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonColor: '#dd4b39',
        confirmButtonText: this.$t('buttons.confirm')
      })

      if (result.value) {
        let items = this.workExperience.work_experience_music.slice()
        items = items.filter((item, index) => {
          return index !== indexb
        })
        this.workExperience.work_experience_music = items
        return true
      } else {
        return false
      }
    }
  }
}
</script>
