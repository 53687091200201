<template>
  <b-card class="mt-4">
    <template v-slot:header>
      <a v-b-toggle.modelData.extraData>
        <h4 class="card-title mt-1" slot="header">Votre inscription a bien été réceptionnée</h4>
        <p>Un email avec le reçu du paiement des frais d'inscription vous a été transmis.</p>
        <strong class="mb-1">{{ $t('labels.backend.registrations.titles.extra_form.title') }}</strong>
      </a>
    </template>
    <b-collapse visible id="documentData" role="tabpanel">
      <b-card-body>
        <div v-if="!modelData.extra_form || !modelData.extra_form.how_do_you_know_lfi">
          <h5 class="mb-4">{{ $t('labels.backend.registrations.titles.extra_form.description') }}</h5>
          <div id="checkbox-group-2" name="flavour-2" v-for="answer in answers" :key="answer.id">
            <b-form-checkbox :ref="answer.type" v-model="extraData.how_do_you_know_lfi" :value="answer">{{ $t('validation.attributes.registration.extra_form.' + answer.type) }}</b-form-checkbox>
            <b-form-group
              :label="$t('validation.attributes.registration.extra_form.detail')"
              :label-for="'note'"
              class="col-md-12 mt-3"
              v-if="checkAnswer(answer.type)"
            >
              <b-form-input
                :name="'note'"
                required
                :placeholder="$t('validation.attributes.registration.extra_form.detail')"
                v-model="answer.note"
                :class="{ 'is-invalid': $v.extraData.how_do_you_know_lfi.$each[extraData.how_do_you_know_lfi.map(function(e) { return e.id }).indexOf(answer.id)] ? $v.extraData.how_do_you_know_lfi.$each[extraData.how_do_you_know_lfi.map(function(e) { return e.id }).indexOf(answer.id)].note.$error : false }"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.extraData.how_do_you_know_lfi.$each[extraData.how_do_you_know_lfi.map(function(e) { return e.id }).indexOf(answer.id)]" :state="!$v.extraData.how_do_you_know_lfi.$each[extraData.how_do_you_know_lfi.map(function(e) { return e.id }).indexOf(answer.id)].note.$error">
                Ce champs est requis
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="text-center mt-3">
            <button @click="submitExtraForm()" :disabled="pending" class="btn btn-danger finish-button mx-auto">Envoyer</button>
          </div>
        </div>
        <div class=" text-center" v-if="modelData.extra_form && modelData.extra_form.how_do_you_know_lfi.length > 0">
          Merci pour votre participation.
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import axios from 'axios'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'ExtraForm',
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pending: false,
      extraData: {
        how_do_you_know_lfi: []
      },
      answers: [
        {
          id: 0,
          type: 'search_engine',
          note: ''
        },
        {
          id: 1,
          type: 'alumni',
          note: ''
        },
        {
          id: 2,
          type: 'press',
          note: ''
        },
        {
          id: 3,
          type: 'event',
          note: ''
        },
        {
          id: 4,
          type: 'pro',
          note: ''
        },
        {
          id: 5,
          type: 'website',
          note: ''
        },
        {
          id: 6,
          type: 'pole_emploi',
          note: ''
        },
        {
          id: 7,
          type: 'local_mission',
          note: ''
        },
        {
          id: 8,
          type: 'pro_organization',
          note: ''
        },
        {
          id: 9,
          type: 'staff',
          note: ''
        },
        {
          id: 10,
          type: 'others',
          note: ''
        }
      ]
    }
  },
  validations: {
    extraData: {
      how_do_you_know_lfi: {
        $each: {
          note: {
            required: requiredIf(function (value) {
              return this.$refs[value.type][0].isChecked === true
            })
          }
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    async submitExtraForm () {
      if (this.validate()) {
        this.pending = true
        let action = this.$app.route('user.extra_form.store')
        this.extraData.registration_id = this.modelData.id
        let formData = this.$app.objectToFormData(this.extraData)

        try {
          let { data } = await axios.post(action, formData)
          this.pending = false
          // this.$app.noty[data.status](data.message)
          this.formSuccess(data)
          this.$emit('context-changed', { 'relation': 'extra_form', 'property': 'how_do_you_know_lfi', 'value': this.extraData.how_do_you_know_lfi })
          return data
        } catch (e) {
          this.pending = false
          // Validation errors
          if (e.response.status === 422) {
            this.validation = e.response.data
            return
          }

          this.$app.error(e)
        }
      }
    },
    validate () {
      this.$v.extraData.$touch()
      var isValid = !this.$v.extraData.$invalid
      this.$emit('on-validate', this.$v.extraData, isValid)
      return isValid
    },
    formSuccess () {
      this.$emit('context-changed', { 'relation': null, 'property': 'has_extra_form', 'value': true })
    },
    checkAnswer (type) {
      if (this.$refs[type]) {
        return this.$refs[type][0].isChecked
      } else {
        return false
      }
    }
  }
}
</script>
