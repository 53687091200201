import axios from 'axios'

export default {
  props: ['id'],
  data () {
    return {
      validation: {},
      pending: false
    }
  },
  computed: {
    isNew () {
      return this.id === undefined
    }
  },
  methods: {
    async fetchData () {
      if (!this.isNew) {
        let { data } = await axios.get(this.$app.route(`user.${this.resourceRoute}.show`, {
          [this.modelName]: this.id
        }))

        Object.keys(data).forEach((key) => {
          if (key in this.model) {
            this.model[key] = data[key]
          }
        })
        this.onModelChanged()
      }
    },
    onModelChanged () {
      this.$emit('model-changed')
    },
    feedback (name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state (name) {
      return this.validation.errors !== undefined && this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    async onSubmit () {
      this.pending = true
      let action = this.isNew ? this.$app.route(
        `user.${this.resourceRoute}.store`) : this.$app.route(
        `user.${this.resourceRoute}.update`, { [this.modelName]: this.id })

      let formData = this.$app.objectToFormData(this.model)
      if (!this.isNew) {
        formData.append('_method', 'PATCH')
      }

      try {
        let { data } = await axios.post(action, formData)
        this.pending = false
        // this.$app.noty[data.status](data.message)
        this.formSuccess(data)
        return data
      } catch (e) {
        this.pending = false
        // Validation errors
        if (e.response.status === 422) {
          this.validation = e.response.data
          return
        }

        this.$app.error(e)
      }
    },
    formSuccess () {
      if (this.listPath) {
        let router = this.$router
        router.push(this.listPath)
      }
    },
    textLimitCounter (field, limit) {
      if (field === null || field === undefined) {
        return limit + ' caractères disponibles'
      }

      const diff = limit - field.length

      if (diff === 1) {
        return '1 caractère disponible'
      }

      if (diff <= 0) {
        return '0 caractère disponible'
      }

      return diff + ' caractères disponibles'
    }
  },
  created () {
    this.fetchData()
  }
}
