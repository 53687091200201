<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.modelData.educationData>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.education.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="educationData" role="tabpanel">
      <b-card-body>
        <b-card class="studies mb-4">
          <b-card-title>
            <h4 class="card-title" slot="header">{{ $t('labels.backend.registrations.titles.education.studies') }}</h4>
          </b-card-title>
          <b-card-body class="study" v-for="(study, index) in educationData.studies" :key="index">
            <div class="row">
              <div class="col-12 mb-5"><h5 class="text-center">Formation {{ index+1 }}</h5></div>
              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.education.study_title')"
                label-for="study_title"
                :feedback="feedback('study_title')"
              >
                <b-form-input
                  id="study_title"
                  name="study_title"
                  required
                  :placeholder="$t('validation.attributes.registration.education.study_title')"
                  v-model="educationData.studies[index].title"
                  :state="state('study_title')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].title.$error }"
                  @input="debounceInput('studies', 'title', index)"
                  @change="onContextChanged('studies', 'title', index)"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].title.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.education.study_degree')"
                label-for="study_degree"
                :feedback="feedback('study_degree')"
              >
                <b-form-input
                  id="study_degree"
                  name="study_degree"
                  required
                  :placeholder="$t('validation.attributes.registration.education.study_degree')"
                  v-model="educationData.studies[index].degree"
                  :state="state('study_degree')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].degree.$error }"
                  @input="debounceInput('studies', 'degree', index)"
                  @change="onContextChanged('studies', 'degree', index)"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].degree.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                label-for="study_level"
                :feedback="feedback('study_level')"
              >
                <template v-slot:label><span v-html="$t('validation.attributes.registration.education.study_level')"></span></template>
                <b-form-select
                  id="study_level"
                  name="study_level"
                  v-model="educationData.studies[index].level"
                  :options="study_level_options"
                  :placeholder="$t('validation.attributes.registration.education.study_duration')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].level.$error }"
                  @input="debounceInput('studies', 'level', index)"
                  @change="onContextChanged('studies', 'level', index)"
                ></b-form-select>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].level.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-6"
                :label="$t('validation.attributes.registration.education.study_duration')"
                label-for="study_duration"
              >
                <b-form-select
                  id="study_duration"
                  name="study_duration"
                  v-model="educationData.studies[index].duration"
                  :options="study_duration_options"
                  :placeholder="$t('validation.attributes.registration.education.study_duration')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].duration.$error }"
                  @input="debounceInput('studies', 'duration', index)"
                  @change="onContextChanged('studies', 'duration', index)"
                ></b-form-select>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].duration.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.education.study_school_name')"
                label-for="study_school_name"
                :feedback="feedback('study_school_name')"
              >
                <b-form-input
                  id="study_school_name"
                  name="study_school_name"
                  required
                  :placeholder="$t('validation.attributes.registration.education.study_school_name')"
                  v-model="educationData.studies[index].school_name"
                  :state="state('study_school_name')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].school_name.$error }"
                  @input="debounceInput('studies', 'school_name', index)"
                  @change="onContextChanged('studies', 'school_name', index)"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].school_name.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.education.study_graduation_date')"
                label-for="study_graduation_date"
              >
                <b-input-group>
                  <p-datetimepicker
                    id="study_graduation_date"
                    name="study_graduation_date"
                    :config="config"
                    v-model="educationData.studies[index].graduation_date"
                    :class="{ 'is-invalid': $v.educationData.studies.$each[index].graduation_date.$error }"
                    @change="onContextChanged('studies', 'graduation_date', index)"
                  ></p-datetimepicker>
                  <b-input-group-append>
                    <b-input-group-text data-toggle>
                      <i class="fe fe-calendar"></i>
                    </b-input-group-text>
                    <b-input-group-text data-clear>
                      <i class="fe fe-x-circle"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].graduation_date.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="col-4"
                :label="$t('validation.attributes.registration.education.study_city')"
                label-for="study_city"
                :feedback="feedback('study_city')"
              >
                <b-form-input
                  id="study_city"
                  name="study_city"
                  required
                  :placeholder="$t('validation.attributes.registration.education.study_city')"
                  v-model="educationData.studies[index].city"
                  :state="state('study_city')"
                  :class="{ 'is-invalid': $v.educationData.studies.$each[index].city.$error }"
                  @input="debounceInput('studies', 'city', index)"
                  @change="onContextChanged('studies', 'city', index)"
                ></b-form-input>
                <b-form-invalid-feedback :state="!$v.educationData.studies.$each[index].city.$error">
                  Ce champs est requis
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="col-12 mb-5 mt-3 text-center"><div class="btn btn-warning btn-sm mx-auto text-center" @click="removeStudy(index)"><i class="fe fe-trash"></i> &nbsp; {{ $t('buttons.registration.relations.education.remove_study') }}</div></div>
              <div class="col"><hr></div>
            </div>
          </b-card-body>
          <b-button size="sm" class="w-100"
                    :disabled="pending"
                    @click="addStudy"
                    variant="dark"
          >
            {{ $t('buttons.registration.relations.education.add_study') }}
          </b-button>
        </b-card>

        <b-form-group
          label-for="english_level"
          v-if="modelData.campaign.course.english"
        >
          <template v-slot:label><span v-html="$t('validation.attributes.registration.education.english_level')"></span></template>
          <b-form-select
            @change="onContextChanged('english_level')"
            id="english_level"
            name="english_level"
            v-model="educationData.english_level"
            :options="english_level_options"
            :placeholder="$t('validation.attributes.english_level')"
            :class="{ 'is-invalid': $v.educationData.english_level.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.educationData.english_level.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.education.english_note')"
          label-for="english_note"
          :feedback="feedback('english_note')"
          v-if="modelData.campaign.course.english && (educationData.english_level === 1 || educationData.english_level === 2)"
        >
          <b-form-input
            @input="debounceInput('english_note')"
            @change="onContextChanged('english_note')"
            id="english_note"
            name="english_note"
            required
            :placeholder="$t('validation.attributes.registration.education.english_note')"
            v-model="educationData.english_note"
            :state="state('english_note')"
            :class="{ 'is-invalid': $v.educationData.english_note.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.educationData.english_note.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.education.word_level')"
          label-for="word_level"
          v-if="modelData.campaign.course.office_apps"
        >
          <b-form-select
            @change="onContextChanged('word_level')"
            id="word_level"
            name="word_level"
            v-model="educationData.word_level"
            :options="level_options"
            :placeholder="$t('validation.attributes.registration.education.word_level')"
            :class="{ 'is-invalid': $v.educationData.word_level.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.educationData.word_level.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.education.excel_level')"
          label-for="excel_level"
          v-if="modelData.campaign.course.office_apps"
        >
          <b-form-select
            @change="onContextChanged('excel_level')"
            id="excel_level"
            name="excel_level"
            v-model="educationData.excel_level"
            :options="level_options"
            :placeholder="$t('validation.attributes.registration.education.excel_level')"
            :class="{ 'is-invalid': $v.educationData.excel_level.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.educationData.word_level.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'EducationForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      educationData: {},
      addRows: [],
      level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Niveau Débutant' },
        { value: 2, text: 'Niveau intermédiaire' },
        { value: 3, text: 'Niveau avancé' },
        { value: 4, text: 'Niveau expert' }
      ],
      study_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Niveau 3 (Ex niv. V : CAP, BEP)' },
        { value: 2, text: 'Niveau 4 (Ex niv. IV, Bac)' },
        { value: 3, text: 'Niveau 5 (Ex niv. III, Bac +2 : DEUG, BTS, DUT, DEUST)' },
        { value: 4, text: 'Niveau 6 (Ex niv. II, Bac +3/+4 : Licence, licence professionnelle, Maîtrise, master 1)' },
        { value: 5, text: 'Niveau 7 (Ex niv. I, Bac +5 : Master 2, DEA, DESS, Ingénieur)' },
        { value: 6, text: 'Niveau 8 (Ex niv. I, Bac +8 : Doctorat)' }

      ],
      english_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'A1' },
        { value: 2, text: 'A2' },
        { value: 3, text: 'B1' },
        { value: 4, text: 'B2' },
        { value: 5, text: 'C1' },
        { value: 6, text: 'C2' }
      ],
      study_duration_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 0, text: '- 5 jours' },
        { value: 1, text: '10 jours' },
        { value: 2, text: '15 jours' },
        { value: 3, text: '1 mois' },
        { value: 4, text: '2 mois' },
        { value: 5, text: '3 mois' },
        { value: 6, text: '4 mois' },
        { value: 7, text: '6 mois' },
        { value: 8, text: '6 mois - 1 an' },
        { value: 9, text: '1 an' },
        { value: 10, text: '2 ans' },
        { value: 11, text: '3 ans' },
        { value: 12, text: '4 ans' },
        { value: 13, text: '5 ans' },
        { value: 14, text: '6 ans' },
        { value: 15, text: '7 ans' },
        { value: 16, text: '8 ans' },
        { value: 17, text: '9 ans' },
        { value: 18, text: '10 ans' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: true,
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        dateFormat: 'Y-m-d H:i:S',
        altFormat: 'd/m/Y'
      },
      defaultStudy: {
        title: null,
        degree: null
      }
    }
  },
  validations: {
    educationData: {
      studies: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(5),
        $each: {
          title: {
            required
          },
          degree: {
            required
          },
          level: {
            required
          },
          duration: {
            required
          },
          school_name: {
            required
          },
          graduation_date: {
            required
          },
          city: {
            required
          }
        }
      },
      english_level: {
        required: requiredIf(function () { return this.modelData.campaign.course.english === true })
      },
      english_note: {
        required: requiredIf(function () { return this.educationData.english_level === 1 || this.educationData.english_level === 2 })
      },
      word_level: {
        required: requiredIf(function () { return this.modelData.campaign.course.office_apps === true })
      },
      excel_level: {
        required: requiredIf(function () { return this.modelData.campaign.course.office_apps === true })
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.education_data)) {
      this.educationData = this.modelData.education_data
      if (!this.educationData.studies || this.educationData.studies.length === 0) {
        this.educationData.studies = [{}]
      }
    } else {
      if (_.isEmpty(this.educationData)) {
        this.educationData = { studies: [{}] }
      } else {
        this.educationData.studies = [{}]
      }
      delete this.modelData.education_data
    }
  },
  methods: {
    debounceInput: _.debounce(function (prop, subprop, pindex) {
      this.onContextChanged(prop, subprop, pindex)
    }, 200),
    onContextChanged (prop, subprop, pindex) {
      if (subprop && pindex !== null) {
        this.$v.educationData[prop].$each[pindex][subprop].$touch()
      } else {
        this.$v.educationData[prop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'education_data', 'property': prop, 'value': this.educationData[prop] })
    },
    validate () {
      this.$v.educationData.$touch()
      var isValid = !this.$v.educationData.$invalid
      this.$emit('on-validate', this.$v.educationData, isValid)
      return isValid
    },
    addStudy () {
      if (this.educationData && this.educationData.studies && this.educationData.studies.length > 0) {
        this.educationData.studies.push({})
      } else {
        if (_.isEmpty(this.educationData)) {
          this.educationData = { studies: [{}] }
        } else {
          this.educationData.studies = [{}]
        }
      }
    },
    async removeStudy (indexb) {
      let result = await window.swal({
        title: this.$t('labels.are_you_sure'),
        text: 'Souhaitez-vous supprimer cette formation ?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonColor: '#dd4b39',
        confirmButtonText: this.$t('buttons.confirm')
      })

      if (result.value) {
        let items = this.educationData.studies.slice()
        items = items.filter((item, index) => {
          return index !== indexb
        })
        this.educationData.studies = items
        return true
      } else {
        return false
      }
    }
  }
}
</script>
