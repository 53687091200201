<template>
  <b-card class="mt-4">
    <template v-slot:header>
      <a v-b-toggle.workExperienceRegProdForm>
        <h2 class="card-title mt-1 mb-1" slot="header">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_regprod.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="workExperienceRegProdForm" role="tabpanel">
      <b-card-body>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.sound')"
          label-for="sound"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="sound"
            name="sound"
            v-model="workExperience.work_experience_reg_prod.sound"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.sound')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.sound.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.sound.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.sound_notes')"
          label-for="sound_notes"
          :feedback="feedback('sound_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.sound_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.sound > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="sound_notes"
            name="sound_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.sound_notes')"
            v-model="workExperience.work_experience_reg_prod.sound_notes"
            :state="state('sound_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.sound_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.sound_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.light')"
          label-for="light"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="light"
            name="light"
            v-model="workExperience.work_experience_reg_prod.light"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.light')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.light.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.light.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.light_notes')"
          label-for="light_notes"
          :feedback="feedback('light_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.light_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.light > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="light_notes"
            name="light_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.light_notes')"
            v-model="workExperience.work_experience_reg_prod.light_notes"
            :state="state('light_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.light_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.light_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.stage')"
          label-for="stage"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="stage"
            name="stage"
            v-model="workExperience.work_experience_reg_prod.stage"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.stage')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.stage.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.stage.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.stage_notes')"
          label-for="stage_notes"
          :feedback="feedback('stage_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.stage_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.stage > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="stage_notes"
            name="stage_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.stage_notes')"
            v-model="workExperience.work_experience_reg_prod.stage_notes"
            :state="state('stage_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.stage_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.stage_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.tech_docs')"
          label-for="tech_docs"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="tech_docs"
            name="tech_docs"
            v-model="workExperience.work_experience_reg_prod.tech_docs"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.tech_docs')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.tech_docs.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.tech_docs.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.tech_docs_notes')"
          label-for="tech_docs_notes"
          :feedback="feedback('tech_docs_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.tech_docs_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.tech_docs > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="tech_docs_notes"
            name="tech_docs_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.tech_docs_notes')"
            v-model="workExperience.work_experience_reg_prod.tech_docs_notes"
            :state="state('tech_docs_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.tech_docs_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.tech_docs_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.planning')"
          label-for="planning"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="planning"
            name="planning"
            v-model="workExperience.work_experience_reg_prod.planning"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.planning')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.planning.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.planning.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.planning_notes')"
          label-for="planning_notes"
          :feedback="feedback('planning_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.planning_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.planning > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="planning_notes"
            name="planning_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.planning_notes')"
            v-model="workExperience.work_experience_reg_prod.planning_notes"
            :state="state('planning_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.planning_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.planning_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.budget')"
          label-for="budget"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="budget"
            name="budget"
            v-model="workExperience.work_experience_reg_prod.budget"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.budget')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.budget.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.budget.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.budget_notes')"
          label-for="budget_notes"
          :feedback="feedback('budget_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.budget_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.budget > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="budget_notes"
            name="budget_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.budget_notes')"
            v-model="workExperience.work_experience_reg_prod.budget_notes"
            :state="state('budget_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.budget_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.budget_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.contract')"
          label-for="contract"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="contract"
            name="contract"
            v-model="workExperience.work_experience_reg_prod.contract"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.contract')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.contract.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.contract.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.contract_notes')"
          label-for="contract_notes"
          :feedback="feedback('contract_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.contract_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.contract > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="contract_notes"
            name="contract_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.contract_notes')"
            v-model="workExperience.work_experience_reg_prod.contract_notes"
            :state="state('contract_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.contract_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.contract_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.team_management')"
          label-for="team_management"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="team_management"
            name="team_management"
            v-model="workExperience.work_experience_reg_prod.team_management"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.team_management')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.team_management.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.team_management.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.team_management_notes')"
          label-for="team_management_notes"
          :feedback="feedback('team_management_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.team_management_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.team_management > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="team_management_notes"
            name="team_management_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.team_management_notes')"
            v-model="workExperience.work_experience_reg_prod.team_management_notes"
            :state="state('team_management_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.team_management_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.team_management_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.security')"
          label-for="security"
        >
          <b-form-select
            @change="onContextChanged('work_experience_reg_prod')"
            id="security"
            name="security"
            v-model="workExperience.work_experience_reg_prod.security"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.security')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.security.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.security.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_regprod.security_notes')"
          label-for="security_notes"
          :feedback="feedback('security_notes')"
          :description="textLimitCounter(workExperience.work_experience_reg_prod.security_notes, 512)"
          v-if="workExperience.work_experience_reg_prod.security > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_reg_prod')"
            @change="onContextChanged('work_experience_reg_prod')"
            id="security_notes"
            name="security_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_regprod.security_notes')"
            v-model="workExperience.work_experience_reg_prod.security_notes"
            :state="state('security_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_reg_prod.security_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_reg_prod.security_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import { required, maxLength } from 'vuelidate/lib/validators'
import form from '../../mixins/form'
import _ from 'lodash'

export default {
  name: 'WorkExperienceRegProdForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      workExperience: {
        work_experience_reg_prod: {}
      },
      knowledge_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Pas de connaissances' },
        { value: 2, text: 'Quelques connaissances' },
        { value: 3, text: 'Bonnes connaissances' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations: {
    workExperience: {
      work_experience_reg_prod: {
        sound: { required },
        sound_notes: { maxLength: maxLength(512) },
        light: { required },
        light_notes: { maxLength: maxLength(512) },
        stage: { required },
        stage_notes: { maxLength: maxLength(512) },
        tech_docs: { required },
        tech_docs_notes: { maxLength: maxLength(512) },
        planning: { required },
        planning_notes: { maxLength: maxLength(512) },
        budget: { required },
        budget_notes: { maxLength: maxLength(512) },
        contract: { required },
        contract_notes: { maxLength: maxLength(512) },
        team_management: { required },
        team_management_notes: { maxLength: maxLength(512) },
        security: { required },
        security_notes: { maxLength: maxLength(512) }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.work_experience)) {
      this.workExperience = this.modelData.work_experience
      if (_.isEmpty(this.workExperience.work_experience_reg_prod)) {
        this.workExperience.work_experience_reg_prod = {}
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp, subprop) {
      this.onContextChanged(myProp, subprop)
    }, 200),
    onContextChanged (prop, subprop) {
      if (this.$v.workExperience[prop][subprop]) {
        this.$v.workExperience[prop][subprop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'work_experience', 'property': prop, 'value': this.workExperience[prop] })
    },
    validate () {
      this.$v.workExperience.$touch()
      var isValid = !this.$v.workExperience.$invalid
      this.$emit('on-validate', this.$v.workExperience, isValid)
      return isValid
    }
  }
}
</script>
