<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.profesionalStatus>
        <h2 class="card-title mb-1 mt-1">{{ $t('labels.backend.registrations.titles.professional_status.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="profesionalStatus" role="tabpanel">
      <b-card-body>
        <div class="row">
          <b-form-group
            :label="$t('validation.attributes.job_status')"
            label-for="job_status"
            class="col-md-12"
          >
            <b-form-select
              id="job_status"
              name="job_status"
              v-model="professionalStatus.job_status"
              :options="job_status_options"
              :placeholder="$t('validation.attributes.job_status')"
              class=""
              @change="onJobStatusChanged()"
              :class="{ 'is-invalid': $v.professionalStatus.job_status.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_status.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_status_note')"
            label-for="job_status_note"
            class="col-md-12"
            :feedback="feedback('job_status_note')"
            :description="textLimitCounter(professionalStatus.job_status_note, 512)"
          >
            <b-form-textarea
              id="job_status_note"
              name="job_status_note"
              :placeholder="$t('validation.attributes.job_status_note')"
              v-model="professionalStatus.job_status_note"
              :state="state('job_status_note')"
              @input="debounceInput('job_status_note')"
              :class="{ 'is-invalid': $v.professionalStatus.job_status_note.$error }"
            ></b-form-textarea>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_status_note.$error">
              Ce champs est requis - 512 caractères maximum
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-12 py-4" v-if="current_job_status"></div>

        <div class="pole_emploi row" v-if="current_job_status === 1">

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_registration_date')"
            label-for="pole_emploi_registration_date"
            class="col-md-4"
          >
            <b-input-group>
              <p-datetimepicker
                id="pole_emploi_registration_date"
                name="pole_emploi_registration_date"
                :config="config"
                v-model="professionalStatus.pole_emploi_registration_date"
                @change="onContextChanged('pole_emploi_registration_date')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_login')"
            label-for="pole_emploi_login"
            :feedback="feedback('pole_emploi_login')"
            class="col-md-4"
          >
            <b-form-input
              id="pole_emploi_login"
              name="pole_emploi_login"
              :placeholder="$t('validation.attributes.pole_emploi_login')"
              v-model="professionalStatus.pole_emploi_login"
              :state="state('pole_emploi_login')"
              @input="debounceInput('pole_emploi_login')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_region')"
            label-for="pole_emploi_region"
            :feedback="feedback('pole_emploi_region')"
            class="col-md-4"
          >
            <b-form-select
              id="pole_emploi_region"
              name="pole_emploi_region"
              v-model="professionalStatus.pole_emploi_region"
              :options="pole_emploi_region_options"
              :placeholder="$t('validation.attributes.pole_emploi_region')"
              :class="{ 'is-invalid': $v.professionalStatus.pole_emploi_region.$error }"
              @change="onContextChanged('pole_emploi_region')"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_indem_date_start')"
            label-for="pole_emploi_indem_date_start"
            class="col-md-4"
          >
            <b-input-group>
              <p-datetimepicker
                id="pole_emploi_indem_date_start"
                name="pole_emploi_indem_date_start"
                :config="config"
                v-model="professionalStatus.pole_emploi_indem_date_start"
                @change="onContextChanged('pole_emploi_indem_date_start')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_indem_date_end')"
            label-for="pole_emploi_indem_date_end"
            class="col-md-4"
          >
            <b-input-group>
              <p-datetimepicker
                id="pole_emploi_indem_date_end"
                name="pole_emploi_indem_date_end"
                :config="config"
                v-model="professionalStatus.pole_emploi_indem_date_end"
                @change="onContextChanged('pole_emploi_indem_date_end')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_aides')"
            label-for="pole_emploi_aides"
            class="col-md-4"
          >
            <b-form-select
              id="pole_emploi_aides"
              name="pole_emploi_aides"
              v-model="professionalStatus.pole_emploi_aides"
              :options="pole_emploi_aides_options"
              :placeholder="$t('validation.attributes.pole_emploi_aides')"
              class=""
              @change="onContextChanged('pole_emploi_aides')"
            ></b-form-select>
          </b-form-group>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_pdc')"
            label-for="pole_emploi_pdc"
            class="col-md-12"
          >
            <b-form-select
              id="pole_emploi_pdc"
              name="pole_emploi_pdc"
              v-model="professionalStatus.pole_emploi_pdc"
              :options="[{ value: 0, text: 'OUI' },{ value: 1, text: 'NON' }]"
              :placeholder="$t('validation.attributes.pole_emploi_pdc')"
              class=""
              @change="onContextChanged('pole_emploi_pdc')"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_cpf')"
            label-for="pole_emploi_cpf"
            class="col-md-12"
          >
            <b-form-select
              id="pole_emploi_cpf"
              name="pole_emploi_cpf"
              v-model="professionalStatus.pole_emploi_cpf"
              :options="[{ value: 0, text: 'OUI' },{ value: 1, text: 'NON' }]"
              :placeholder="$t('validation.attributes.pole_emploi_cpf')"
              class=""
              @change="onContextChanged('pole_emploi_cpf')"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.pole_emploi_rsa')"
            label-for="rsa"
            class="col-md-12"
          >
            <b-form-select
              id="rsa"
              name="rsa"
              v-model="professionalStatus.rsa"
              :options="[{ value: 0, text: 'OUI' },{ value: 1, text: 'NON' }]"
              :placeholder="$t('validation.attributes.rsa')"
              class=""
              @change="onContextChanged('rsa')"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="employed row" v-if="current_job_status > 1">

          <b-form-group
            :label="$t('validation.attributes.job_company_name')"
            label-for="job_company_name"
            class="col-md-6"
            :feedback="feedback('job_company_name')"
          >
            <b-form-input
              id="job_company_name"
              name="job_company_name"
              :placeholder="$t('validation.attributes.job_company_name')"
              v-model="professionalStatus.job_company_name"
              :state="state('job_company_name')"
              @input="debounceInput('job_company_name')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_name.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_name.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_date_start')"
            label-for="job_date_start"
            class="col-md-6"
          >
            <b-input-group>
              <p-datetimepicker
                id="job_date_start"
                name="job_date_start"
                :config="config"
                v-model="professionalStatus.job_date_start"
                @change="onContextChanged('job_date_start')"
                :class="{ 'is-invalid': $v.professionalStatus.job_date_start.$error }"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_date_start.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_position')"
            label-for="job_position"
            :feedback="feedback('job_position')"
            class="col-md-6"
          >
            <b-form-input
              id="job_position"
              name="job_position"
              :placeholder="$t('validation.attributes.job_position')"
              v-model="professionalStatus.job_position"
              :state="state('job_position')"
              @input="debounceInput('job_position')"
              :class="{ 'is-invalid': $v.professionalStatus.job_position.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_position.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_company_size')"
            label-for="job_company_size"
            class="col-md-6"
          >
            <b-form-select
              id="job_company_size"
              name="job_company_size"
              v-model="professionalStatus.job_company_size"
              :options="[{ value: 0, text: '- de 11 salariés' },{ value: 1, text: '11 à 50 salariés' },{ value: 2, text: '+ de 50 salariés' }]"
              :placeholder="$t('validation.attributes.job_company_size')"
              class=""
              @change="onContextChanged('job_company_size')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_size.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_size.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.job_company_address')"
            label-for="job_company_address"
            class="col-md-12"
            :feedback="feedback('job_company_address')"
          >
            <b-form-input
              id="job_company_address"
              name="job_company_address"
              :placeholder="$t('validation.attributes.job_company_address')"
              v-model="professionalStatus.job_company_address"
              :state="state('job_company_address')"
              @input="debounceInput('job_company_address')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_address.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_address.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_company_city')"
            label-for="job_company_city"
            class="col-md-4"
            :feedback="feedback('job_company_city')"
          >
            <b-form-input
              id="job_company_city"
              name="job_company_city"
              :placeholder="$t('validation.attributes.job_company_city')"
              v-model="professionalStatus.job_company_city"
              :state="state('job_company_city')"
              @input="debounceInput('job_company_city')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_city.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_city.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_company_zipcode')"
            label-for="job_company_zipcode"
            class="col-md-4"
            :feedback="feedback('job_company_zipcode')"
          >
            <b-form-input
              id="job_company_zipcode"
              name="job_company_zipcode"
              :placeholder="$t('validation.attributes.job_company_zipcode')"
              v-model="professionalStatus.job_company_zipcode"
              :state="state('job_company_zipcode')"
              @input="debounceInput('job_company_zipcode')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_zipcode.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_zipcode.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_company_phone')"
            label-for="job_company_phone"
            class="col-md-4"
            :feedback="feedback('job_company_phone')"
          >
            <b-form-input
              id="job_company_phone"
              name="job_company_phone"
              :placeholder="$t('validation.attributes.job_company_phone')"
              v-model="professionalStatus.job_company_phone"
              :state="state('job_company_phone')"
              @input="debounceInput('job_company_phone')"
              @change="onContextChanged('job_company_phone')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_phone.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_phone.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.job_manager_name')"
            label-for="job_manager_name"
            class="col-md-6"
            :feedback="feedback('job_manager_name')"
          >
            <b-form-input
              id="job_manager_name"
              name="job_manager_name"
              :placeholder="$t('validation.attributes.job_manager_name')"
              v-model="professionalStatus.job_manager_name"
              :state="state('job_manager_name')"
              @input="debounceInput('job_manager_name')"
              :class="{ 'is-invalid': $v.professionalStatus.job_manager_name.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_manager_name.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_manager_firstname')"
            label-for="job_manager_firstname"
            class="col-md-6"
            :feedback="feedback('job_manager_firstname')"
          >
            <b-form-input
              id="job_manager_firstname"
              name="job_manager_firstname"
              :placeholder="$t('validation.attributes.job_manager_firstname')"
              v-model="professionalStatus.job_manager_firstname"
              :state="state('job_manager_firstname')"
              @input="debounceInput('job_manager_firstname')"
              :class="{ 'is-invalid': $v.professionalStatus.job_manager_firstname.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_manager_firstname.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_manager_phone')"
            label-for="job_manager_phone"
            class="col-md-6"
            :feedback="feedback('job_manager_phone')"
          >
            <b-form-input
              id="job_manager_phone"
              name="job_manager_phone"
              :placeholder="$t('validation.attributes.job_manager_phone')"
              v-model="professionalStatus.job_manager_phone"
              :state="state('job_manager_phone')"
              @input="debounceInput('job_manager_phone')"
              :class="{ 'is-invalid': $v.professionalStatus.job_manager_phone.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_manager_phone.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_manager_email')"
            label-for="job_manager_email"
            class="col-md-6"
            :feedback="feedback('job_manager_email')"
          >
            <b-form-input
              id="job_manager_email"
              name="job_manager_email"
              :placeholder="$t('validation.attributes.job_manager_email')"
              v-model="professionalStatus.job_manager_email"
              :state="state('job_manager_email')"
              @input="debounceInput('job_manager_email')"
              :class="{ 'is-invalid': $v.professionalStatus.job_manager_email.$error }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_manager_email.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="col-md-12 py-4"></div>

          <b-form-group
            :label="$t('validation.attributes.job_company_approved')"
            label-for="job_company_approved"
            class="col-md-6"
          >
            <b-form-select
              id="job_company_approved"
              name="job_company_approved"
              v-model="professionalStatus.job_company_approved"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' }]"
              :placeholder="$t('validation.attributes.job_company_approved')"
              class=""
              @change="onContextChanged('job_company_approved')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_approved.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_approved.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_moncompteactivite_created')"
            label-for="job_moncompteactivite_created"
            class="col-md-6"
          >
            <b-form-select
              id="job_moncompteactivite_created"
              name="job_moncompteactivite_created"
              v-model="professionalStatus.job_moncompteactivite_created"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' }]"
              :placeholder="$t('validation.attributes.job_moncompteactivite_created')"
              class=""
              @change="onContextChanged('job_moncompteactivite_created')"
              :class="{ 'is-invalid': $v.professionalStatus.job_moncompteactivite_created.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_moncompteactivite_created.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_cpf')"
            label-for="job_cpf"
            class="col-md-6"
          >
            <b-form-select
              id="job_cpf"
              name="job_cpf"
              v-model="professionalStatus.job_cpf"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' },{ value: 2, text: 'Ne sais pas' }]"
              :placeholder="$t('validation.attributes.job_cpf')"
              @change="onContextChanged('job_cpf')"
              :class="{ 'is-invalid': $v.professionalStatus.job_cpf.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_cpf.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_pdc')"
            label-for="job_pdc"
            class="col-md-6"
          >
            <b-form-select
              id="job_pdc"
              name="job_pdc"
              v-model="professionalStatus.job_pdc"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' },{ value: 2, text: 'Ne sais pas' }]"
              :placeholder="$t('validation.attributes.job_pdc')"
              class=""
              @change="onContextChanged('job_pdc')"
              :class="{ 'is-invalid': $v.professionalStatus.job_pdc.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_pdc.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_fne')"
            label-for="job_fne"
            class="col-md-6"
          >
            <b-form-select
              id="job_fne"
              name="job_fne"
              v-model="professionalStatus.job_fne"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' },{ value: 2, text: 'Ne sais pas' }]"
              :placeholder="$t('validation.attributes.job_fne')"
              class=""
              @change="onContextChanged('job_fne')"
              :class="{ 'is-invalid': $v.professionalStatus.job_fne.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_fne.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.job_company_funding')"
            label-for="job_company_funding"
            class="col-md-6"
          >
            <b-form-select
              id="job_company_funding"
              name="job_company_funding"
              v-model="professionalStatus.job_company_funding"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' }]"
              :placeholder="$t('validation.attributes.job_company_funding')"
              class=""
              @change="onContextChanged('job_company_funding')"
              :class="{ 'is-invalid': $v.professionalStatus.job_company_funding.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.job_company_funding.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.formation_other_funding')"
            label-for="formation_other_funding"
            class="col-md-12"
            :feedback="feedback('formation_other_funding')"
            v-if="professionalStatus.job_company_funding === 0"
          >
            <b-form-textarea
              id="formation_other_funding"
              name="formation_other_funding"
              :placeholder="$t('validation.attributes.formation_other_funding')"
              v-model="professionalStatus.formation_other_funding"
              :state="state('formation_other_funding')"
              @input="debounceInput('formation_other_funding')"
              :class="{ 'is-invalid': $v.professionalStatus.formation_other_funding.$error }"
            ></b-form-textarea>
            <b-form-invalid-feedback :state="!$v.professionalStatus.formation_other_funding.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="employed row" v-if="current_job_status">
          <b-form-group
            :label="$t('validation.attributes.is_self_employed')"
            label-for="is_self_employed"
            class="col-md-6"
            :feedback="feedback('is_self_employed')"
          >
            <b-form-select
              id="is_self_employed"
              name="is_self_employed"
              v-model="professionalStatus.is_self_employed"
              :state="state('is_self_employed')"
              :options="[{ value: 0, text: 'Non' },{ value: 1, text: 'Oui' }]"
              :placeholder="$t('validation.attributes.is_self_employed')"
              @change="onContextChanged('is_self_employed')"
              :class="{ 'is-invalid': $v.professionalStatus.is_self_employed.$error }"
            >
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.is_self_employed.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.is_self_employed_date')"
            label-for="is_self_employed_date"
            class="col-md-6"
            v-if="professionalStatus.is_self_employed >= 1"
          >
            <b-input-group>
              <p-datetimepicker
                id="is_self_employed_date"
                name="is_self_employed_date"
                :config="config"
                v-model="professionalStatus.is_self_employed_date"
                @change="onContextChanged('is_self_employed_date')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="employed row" v-if="current_job_status >= 1 && current_job_status !== 2">
          <b-form-group
            :label="$t('validation.attributes.is_intermittent_show')"
            label-for="is_intermittent_show"
            class="col-md-6"
          >
            <b-form-select
              id="is_intermittent_show"
              name="is_intermittent_show"
              v-model="professionalStatus.is_intermittent_show"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' },{ value: 2, text: 'Demande en cours' }]"
              :placeholder="$t('validation.attributes.is_intermittent_show')"
              class=""
              @change="onContextChanged('is_intermittent_show')"
              :class="{ 'is-invalid': $v.professionalStatus.is_intermittent_show.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.is_intermittent_show.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.int_spec_droits_date_start')"
            label-for="int_spec_droits_date_start"
            class="col-md-6"
            v-if="professionalStatus.is_intermittent_show === 1"
          >
            <b-input-group>
              <p-datetimepicker
                id="int_spec_droits_date_start"
                name="int_spec_droits_date_start"
                :config="config"
                v-model="professionalStatus.int_spec_droits_date_start"
                @change="onContextChanged('int_spec_droits_date_start')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.int_spec_droits_date_end')"
            label-for="int_spec_droits_date_end"
            class="col-md-6"
            v-if="professionalStatus.is_intermittent_show === 1"
          >
            <b-input-group>
              <p-datetimepicker
                id="int_spec_droits_date_end"
                name="int_spec_droits_date_end"
                :config="config"
                v-model="professionalStatus.int_spec_droits_date_end"
                @change="onContextChanged('int_spec_droits_date_end')"
              ></p-datetimepicker>
              <b-input-group-append>
                <b-input-group-text data-toggle>
                  <i class="fe fe-calendar"></i>
                </b-input-group-text>
                <b-input-group-text data-clear>
                  <i class="fe fe-x-circle"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-md-12 py-4" v-if="current_job_status"></div>

        <div class="employed row" v-if="current_job_status">

          <b-form-group
            :label="$t('validation.attributes.hand')"
            label-for="hand"
            class="col-md-6"
          >
            <b-form-select
              id="hand"
              name="hand"
              v-model="professionalStatus.hand"
              :options="[{ value: 0, text: 'NON' },{ value: 1, text: 'OUI' }]"
              :placeholder="$t('validation.attributes.hand')"
              class=""
              @change="onContextChanged('hand')"
              :class="{ 'is-invalid': $v.professionalStatus.hand.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.hand.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.hand_type')"
            label-for="hand_type"
            class="col-md-6"
          >
            <b-form-select
              id="hand_type"
              name="hand_type"
              v-model="professionalStatus.hand_type"
              :options="hand_options"
              :placeholder="$t('validation.attributes.hand_type')"
              class=""
              @change="onContextChanged('hand_type')"
              :class="{ 'is-invalid': $v.professionalStatus.hand_type.$error }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!$v.professionalStatus.hand_type.$error">
              Ce champs est requis
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.hand_type_other')"
            label-for="hand_type_other"
            class="col-md-6"
            :feedback="feedback('hand_type_other')"
            v-if="professionalStatus.hand_type === 2"
          >
            <b-form-input
              id="hand_type_other"
              name="hand_type_other"
              :placeholder="$t('validation.attributes.hand_type_other')"
              v-model="professionalStatus.hand_type_other"
              :state="state('hand_type_other')"
              @input="debounceInput('hand_type_other')"
              :class="{ 'is-invalid': $v.professionalStatus.hand_type_other.$error }"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('validation.attributes.hand_services')"
            label-for="hand_services"
            class="col-md-6"
            :feedback="feedback('hand_services')"
          >
            <b-form-input
              id="hand_services"
              name="hand_services"
              :placeholder="$t('validation.attributes.hand_services')"
              v-model="professionalStatus.hand_services"
              :state="state('hand_services')"
              @input="debounceInput('hand_services')"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, email, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'ProfessionalStatusForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      current_job_status: null,
      job_status_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Demandeur.se d\'emploi' },
        { value: 2, text: 'CDI' },
        { value: 3, text: 'CDD' },
        { value: 4, text: 'Contrat aidé' },
        { value: 5, text: 'CDT' },
        { value: 6, text: 'CUI' }
      ],
      pole_emploi_region_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Alpes' },
        { value: 2, text: 'Alpes-provence' },
        { value: 3, text: 'Alsace' },
        { value: 4, text: 'Aquitaine' },
        { value: 5, text: 'Auvergne' },
        { value: 6, text: 'Basse-Normandie' },
        { value: 7, text: 'Bourgogne' },
        { value: 8, text: 'Bretagne' },
        { value: 9, text: 'Centre' },
        { value: 10, text: 'Champagne-Ardennes' },
        { value: 11, text: 'Corse' },
        { value: 12, text: 'Côte d’Azur' },
        { value: 13, text: 'Est Francilien' },
        { value: 14, text: 'Franche Comté' },
        { value: 15, text: 'Guadeloupe' },
        { value: 16, text: 'Guyane' },
        { value: 17, text: 'Haute-Normandie' },
        { value: 18, text: 'La Réunion' },
        { value: 19, text: 'Languedoc Roussillon' },
        { value: 20, text: 'Limousin' },
        { value: 21, text: 'Lorraine' },
        { value: 22, text: 'Martinique' },
        { value: 23, text: 'Mayotte' },
        { value: 24, text: 'Midi Pyrénées' },
        { value: 25, text: 'Ouest Francilien' },
        { value: 26, text: 'Paris' },
        { value: 27, text: 'Pas de Calais' },
        { value: 28, text: 'Pays de la Loire' },
        { value: 29, text: 'Pays du Nord' },
        { value: 30, text: 'Picardie' },
        { value: 31, text: 'Poitou-Charentes' },
        { value: 32, text: 'Saint-Pierre et Miquelon' },
        { value: 33, text: 'Sud-Est Francilien' },
        { value: 34, text: 'Vallées du Rhône - Loire' }
      ],
      pole_emploi_aides_options: [
        { value: 0, text: 'Aucune' },
        { value: 1, text: 'ARE' },
        { value: 2, text: 'ASS' }
      ],
      hand_options: [
        { value: 0, text: 'RQTH' },
        { value: 1, text: 'AAH' },
        { value: 2, text: 'AUTRE(S)' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      },
      professionalStatus: {
        id: null,
        job_status: null,
        job_status_note: null
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (this.modelData.professional_status !== null) {
      this.professionalStatus = this.modelData.professional_status
      this.current_job_status = this.professionalStatus.job_status
    } else {
      delete this.modelData.professional_status
    }
  },
  validations () {
    const isPhoneNumber = (value) => /^[0-9]{10}$/gi.test(value)
    if (this.isPoleEmploi()) {
      return {
        professionalStatus: {
          job_status: { required },
          job_status_note: { maxLength: maxLength(512) },
          hand: { required },
          hand_type: { required: requiredIf(function () { return this.professionalStatus.hand === 1 }) },
          hand_type_other: { required: requiredIf(function () { return this.professionalStatus.hand_type === 2 }) },
          pole_emploi_region: { required },
          is_intermittent_show: { required: requiredIf(function () { return this.professionalStatus.job_status >= 1 && this.professionalStatus.job_status !== 2 }) },
          int_spec_droits_date_start: { required: requiredIf(function () { return this.professionalStatus.is_intermittent_show === 1 }) },
          int_spec_droits_date_end: { required: requiredIf(function () { return this.professionalStatus.is_intermittent_show === 1 }) },
          is_self_employed: { required },
          is_self_employed_date: { required: requiredIf(function () { return this.professionalStatus.is_self_employed === 1 }) }
        }
      }
    } else {
      return {
        professionalStatus: {
          job_status: { required },
          job_status_note: { maxLength: maxLength(512) },
          job_company_name: { required },
          job_date_start: { required },
          job_position: { required },
          job_company_size: { required, numeric },
          job_company_address: { required },
          job_company_city: { required },
          job_company_zipcode: { required },
          job_company_phone: { required, isPhoneNumber },
          job_manager_name: { required },
          job_manager_firstname: { required },
          job_manager_phone: { required, isPhoneNumber },
          job_manager_email: { required, email },
          job_moncompteactivite_created: { required },
          job_company_approved: { required },
          job_cpf: { required },
          job_pdc: { required },
          job_fne: { required },
          job_company_funding: { required },
          formation_other_funding: { required: requiredIf(function () { return this.professionalStatus.job_company_funding === 0 }) },
          is_intermittent_show: { required: requiredIf(function () { return this.professionalStatus.job_status >= 1 && this.professionalStatus.job_status !== 2 }) },
          int_spec_droits_date_start: { required: requiredIf(function () { return this.professionalStatus.is_intermittent_show === 1 }) },
          int_spec_droits_date_end: { required: requiredIf(function () { return this.professionalStatus.is_intermittent_show === 1 }) },
          is_self_employed: { required },
          is_self_employed_date: { required: requiredIf(function () { return this.professionalStatus.is_self_employed === 1 }) },
          hand: { required },
          hand_type: { required: requiredIf(function () { return this.professionalStatus.hand === 1 }) },
          hand_type_other: { required: requiredIf(function () { return this.professionalStatus.hand_type === 2 }) }
        }
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp) {
      this.onContextChanged(myProp)
    }, 200),
    onContextChanged (myProp) {
      if (this.$v.professionalStatus[myProp]) {
        this.$v.professionalStatus[myProp].$touch()
      }
      this.$emit('context-changed', { 'relation': 'professional_status', 'property': myProp, 'value': this.professionalStatus[myProp] })
    },
    async onJobStatusChanged () {
      if (this.current_job_status !== null && (((this.current_job_status > 1 && this.professionalStatus.job_status === 1) || (this.current_job_status === 1 && this.professionalStatus.job_status > 1)) || this.professionalStatus.job_status === null)) {
        let result = await window.swal({
          title: this.$t('labels.are_you_sure'),
          text: this.$t('labels.unsaved_change_will_be_lost'),
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('buttons.cancel'),
          confirmButtonColor: '#dd4b39',
          confirmButtonText: this.$t('buttons.confirm')
        })

        if (result.value) {
          this.current_job_status = this.professionalStatus.job_status
        } else {
          this.professionalStatus.job_status = this.current_job_status
        }
        this.onContextChanged('job_status')
      } else {
        if (this.current_job_status === null) {
          let note = this.professionalStatus.job_status_note
          this.current_job_status = this.professionalStatus.job_status
          this.professionalStatus = { 'job_status': this.current_job_status, 'job_status_note': note }
          this.modelData.professional_status = this.professionalStatus
        } else {
          this.current_job_status = this.professionalStatus.job_status
          this.onContextChanged('job_status')
        }
      }
    },
    validate () {
      this.$v.professionalStatus.$touch()
      var isValid = !this.$v.professionalStatus.$invalid
      this.$emit('on-validate', this.$v.professionalStatus, isValid)
      return isValid
    },
    isContract () {
      return this.current_job_status > 1
    },
    isPoleEmploi () {
      return this.current_job_status === 1
    }
  }
}
</script>
