<template>
  <b-card>
    <template v-slot:header>
      <a v-b-toggle.modelData.professionalProject>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.professional_project.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="professionalProject" role="tabpanel">
      <b-card-body>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.presentation')"
          label-for="presentation"
          :feedback="feedback('presentation')"
          :description="textLimitCounter(professionalProject.presentation, 5000)"
        >
          <b-form-textarea
            @input="debounceInput('presentation')"
            @change="onContextChanged('presentation')"
            id="presentation"
            name="presentation"
            :placeholder="$t('validation.attributes.registration.professional_project.presentation')"
            v-model="professionalProject.presentation"
            :state="state('presentation')"
            :class="{ 'is-invalid': $v.professionalProject.presentation.$error }"
            rows="8"
          ></b-form-textarea>
          <b-form-invalid-feedback :state="!$v.professionalProject.presentation.$error">
            Ce champs est requis - 5000 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.course_benefit')"
          label-for="course_benefit"
          :feedback="feedback('course_benefit')"
          :description="textLimitCounter(professionalProject.course_benefit, 5000)"
        >
          <b-form-textarea
            @input="debounceInput('course_benefit')"
            @change="onContextChanged('course_benefit')"
            id="course_benefit"
            name="course_benefit"
            :placeholder="$t('validation.attributes.registration.professional_project.course_benefit')"
            v-model="professionalProject.course_benefit"
            :state="state('course_benefit')"
            :class="{ 'is-invalid': $v.professionalProject.course_benefit.$error }"
            rows="8"
          ></b-form-textarea>
          <b-form-invalid-feedback :state="!$v.professionalProject.course_benefit.$error">
            Ce champs est requis - 5000 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="col-md-12 py-4"></div>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.internship_company')"
          label-for="internship_company"
        >
          <b-form-select
            @change="onContextChanged('internship_company')"
            id="internship_company"
            name="internship_company"
            v-model="professionalProject.internship_company"
            :options="internship_company_options"
            :placeholder="$t('validation.attributes.registration.professional_project.internship_company')"
            :class="{ 'is-invalid': $v.professionalProject.internship_company.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.professionalProject.internship_company.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.internship_company_name')"
          label-for="internship_company_name"
          :feedback="feedback('internship_company_name')"
          :description="textLimitCounter(professionalProject.internship_company_name, 256)"
          v-if="professionalProject.internship_company === 0"
        >
          <b-form-input
            @input="debounceInput('internship_company_name')"
            @change="onContextChanged('internship_company_name')"
            id="internship_company_name"
            name="internship_company_name"
            :placeholder="$t('validation.attributes.registration.professional_project.internship_company_name')"
            v-model="professionalProject.internship_company_name"
            :state="state('internship_company_name')"
            :class="{ 'is-invalid': $v.professionalProject.internship_company_name.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.professionalProject.internship_company_name.$error">
            Ce champs est requis - 256 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.internship_same_company')"
          label-for="internship_same_company"
          :feedback="feedback('internship_same_company')"
          :description="textLimitCounter(professionalProject.internship_same_company, 256)"
        >
          <b-form-input
            @input="debounceInput('internship_same_company')"
            @change="onContextChanged('internship_same_company')"
            id="internship_same_company"
            name="internship_same_company"
            :placeholder="$t('validation.attributes.registration.professional_project.internship_same_company')"
            v-model="professionalProject.internship_same_company"
            :state="state('internship_same_company')"
            :class="{ 'is-invalid': $v.professionalProject.internship_same_company.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.professionalProject.internship_same_company.$error">
            Ce champs est requis - 256 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.professional_project.how_did_you_know_us')"
          label-for="how_did_you_know_us"
        >
          <b-form-select
            @change="onContextChanged('how_did_you_know_us')"
            id="how_did_you_know_us"
            name="how_did_you_know_us"
            v-model="professionalProject.how_did_you_know_us"
            :options="how_did_you_know_us_options"
            :placeholder="$t('validation.attributes.registration.professional_project.how_did_you_know_us')"
            :class="{ 'is-invalid': $v.professionalProject.how_did_you_know_us.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.professionalProject.how_did_you_know_us.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'ProfessionalProjectForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      professionalProject: {},
      internship_company_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 0, text: 'Oui' },
        { value: 1, text: 'Non' }
      ],
      how_did_you_know_us_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Pôle emploi' },
        { value: 2, text: 'Mission locale' },
        { value: 3, text: 'Cap Emploi / Agefiph' },
        { value: 4, text: 'APEC' },
        { value: 5, text: 'CIO' },
        { value: 6, text: 'OPCO (fongécif, Afdas…)' },
        { value: 7, text: 'Info jeunesse (CRIJ, BIJ)' },
        { value: 8, text: 'Point Emploi des Villes et intercommunalités' },
        { value: 9, text: 'Chambres consulaires (CMA, CCI)' },
        { value: 10, text: 'BGE, Université (SUIO)' },
        { value: 11, text: 'Conseil départemental' },
        { value: 12, text: 'Service étoile info du GIPALFA Centre' },
        { value: 13, text: 'Point relais Conseil' },
        { value: 14, text: 'Auto-orientation' },
        { value: 15, text: 'Autres acteurs' }
      ]
    }
  },
  validations: {
    professionalProject: {
      presentation: {
        required,
        maxLength: maxLength(5000)
      },
      course_benefit: {
        required,
        maxLength: maxLength(5000)
      },
      internship_company: {
        required
      },
      internship_company_name: {
        required: requiredIf(function () { return this.professionalProject.internship_company === 0 }),
        maxLength: maxLength(256)
      },
      internship_same_company: {
        required: requiredIf(function () { return this.professionalProject.internship_company === 0 }),
        maxLength: maxLength(256)
      },
      how_did_you_know_us: { required }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (this.modelData.professional_project !== null) {
      this.professionalProject = this.modelData.professional_project
    } else {
      this.modelData.professional_project = {}
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp) {
      this.onContextChanged(myProp)
    }, 200),
    onContextChanged (myProp) {
      if (this.$v.professionalProject[myProp]) {
        this.$v.professionalProject[myProp].$touch()
      }
      this.$emit('context-changed', { 'relation': 'professional_project', 'property': myProp, 'value': this.professionalProject[myProp] })
    },
    validate () {
      this.$v.professionalProject.$touch()
      var isValid = !this.$v.professionalProject.$invalid
      this.$emit('on-validate', this.$v.professionalProject, isValid)
      return isValid
    }
  }
}
</script>
