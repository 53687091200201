<template>
  <b-card class="mt-4">
    <template v-slot:header>
      <a v-b-toggle.workExperienceChargeProd>
        <h2 class="card-title mt-1 mb-1" slot="header">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_charge_prod.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="workExperienceChargeProd" role="tabpanel">
      <b-card-body>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.gestion_projet_culturel')"
          label-for="gestion_projet_culturel"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'gestion_projet_culturel')"
            id="gestion_projet_culturel"
            name="gestion_projet_culturel"
            v-model="workExperience.work_experience_charge_prod.gestion_projet_culturel"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.gestion_projet_culturel')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.gestion_projet_culturel.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.gestion_projet_culturel.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.gestion_projet_culturel_notes')"
          label-for="gestion_projet_culturel_notes"
          :feedback="feedback('gestion_projet_culturel_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.gestion_projet_culturel_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.gestion_projet_culturel > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'gestion_projet_culturel_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'gestion_projet_culturel_notes')"
            id="gestion_projet_culturel_notes"
            name="gestion_projet_culturel_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.gestion_projet_culturel_notes')"
            v-model="workExperience.work_experience_charge_prod.gestion_projet_culturel_notes"
            :state="state('gestion_projet_culturel_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.gestion_projet_culturel_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.gestion_projet_culturel_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.production_vente_spectacle')"
          label-for="production_vente_spectacle"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'production_vente_spectacle')"
            id="production_vente_spectacle"
            name="production_vente_spectacle"
            v-model="workExperience.work_experience_charge_prod.production_vente_spectacle"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.production_vente_spectacle')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.production_vente_spectacle.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.production_vente_spectacle.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.production_vente_spectacle_notes')"
          label-for="production_vente_spectacle_notes"
          :feedback="feedback('production_vente_spectacle_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.production_vente_spectacle_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.production_vente_spectacle > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'production_vente_spectacle_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'production_vente_spectacle_notes')"
            id="production_vente_spectacle_notes"
            name="production_vente_spectacle_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.production_vente_spectacle_notes')"
            v-model="workExperience.work_experience_charge_prod.production_vente_spectacle_notes"
            :state="state('production_vente_spectacle_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.production_vente_spectacle_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.production_vente_spectacle_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_concert_salle')"
          label-for="organisation_concert_salle"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'organisation_concert_salle' )"
            id="organisation_concert_salle"
            name="organisation_concert_salle"
            v-model="workExperience.work_experience_charge_prod.organisation_concert_salle"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_concert_salle')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.organisation_concert_salle.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.organisation_concert_salle.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_concert_salle_notes')"
          label-for="organisation_concert_salle_notes"
          :feedback="feedback('organisation_concert_salle_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.organisation_concert_salle_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.organisation_concert_salle > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'organisation_concert_salle_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'organisation_concert_salle_notes')"
            id="organisation_concert_salle_notes"
            name="organisation_concert_salle_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_concert_salle_notes')"
            v-model="workExperience.work_experience_charge_prod.organisation_concert_salle_notes"
            :state="state('organisation_concert_salle_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.organisation_concert_salle_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.organisation_concert_salle_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_event_exterieur')"
          label-for="organisation_event_exterieur"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'organisation_event_exterieur')"
            id="organisation_event_exterieur"
            name="organisation_event_exterieur"
            v-model="workExperience.work_experience_charge_prod.organisation_event_exterieur"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_event_exterieur')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.organisation_event_exterieur.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.organisation_event_exterieur.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_event_exterieur_notes')"
          label-for="organisation_event_exterieur_notes"
          :feedback="feedback('organisation_event_exterieur_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.organisation_event_exterieur_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.organisation_event_exterieur > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'organisation_event_exterieur_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'organisation_event_exterieur_notes')"
            id="organisation_event_exterieur_notes"
            name="organisation_event_exterieur_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.organisation_event_exterieur_notes')"
            v-model="workExperience.work_experience_charge_prod.organisation_event_exterieur_notes"
            :state="state('organisation_event_exterieur_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.organisation_event_exterieur_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.organisation_event_exterieur_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.edition_oeuvre_musique')"
          label-for="edition_oeuvre_musique"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'edition_oeuvre_musique')"
            id="edition_oeuvre_musique"
            name="edition_oeuvre_musique"
            v-model="workExperience.work_experience_charge_prod.edition_oeuvre_musique"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.edition_oeuvre_musique')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.edition_oeuvre_musique.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.edition_oeuvre_musique.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.edition_oeuvre_musique_notes')"
          label-for="edition_oeuvre_musique_notes"
          :feedback="feedback('edition_oeuvre_musique_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.edition_oeuvre_musique_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.edition_oeuvre_musique > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'edition_oeuvre_musique_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'edition_oeuvre_musique_notes')"
            id="edition_oeuvre_musique_notes"
            name="edition_oeuvre_musique_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.edition_oeuvre_musique_notes')"
            v-model="workExperience.work_experience_charge_prod.edition_oeuvre_musique_notes"
            :state="state('edition_oeuvre_musique_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.edition_oeuvre_musique_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.edition_oeuvre_musique_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.prod_com_records')"
          label-for="prod_com_records"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'prod_com_records')"
            id="prod_com_records"
            name="prod_com_records"
            v-model="workExperience.work_experience_charge_prod.prod_com_records"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.prod_com_records')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.prod_com_records.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.prod_com_records.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.prod_com_records_notes')"
          label-for="prod_com_records_notes"
          :feedback="feedback('prod_com_records_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.prod_com_records_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.prod_com_records > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'prod_com_records_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'prod_com_records_notes')"
            id="prod_com_records_notes"
            name="prod_com_records_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.prod_com_records_notes')"
            v-model="workExperience.work_experience_charge_prod.prod_com_records_notes"
            :state="state('prod_com_records_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.prod_com_records_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.prod_com_records_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.management_artistic_project')"
          label-for="management_artistic_project"
        >
          <b-form-select
            @change="onContextChanged('work_experience_charge_prod', 'management_artistic_project')"
            id="management_artistic_project"
            name="management_artistic_project"
            v-model="workExperience.work_experience_charge_prod.management_artistic_project"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.management_artistic_project')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.management_artistic_project.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.management_artistic_project.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.management_artistic_project_notes')"
          label-for="management_artistic_project_notes"
          :feedback="feedback('management_artistic_project_notes')"
          :description="textLimitCounter(workExperience.work_experience_charge_prod.management_artistic_project_notes, 512)"
          v-if="workExperience.work_experience_charge_prod.management_artistic_project > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_charge_prod', 'management_artistic_project_notes')"
            @change="onContextChanged('work_experience_charge_prod', 'management_artistic_project_notes')"
            id="management_artistic_project_notes"
            name="management_artistic_project_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_charge_prod.management_artistic_project_notes')"
            v-model="workExperience.work_experience_charge_prod.management_artistic_project_notes"
            :state="state('management_artistic_project_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_charge_prod.management_artistic_project_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_charge_prod.management_artistic_project_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import form from '../../mixins/form'
import _ from 'lodash'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'WorkExperienceChargeProd',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      workExperience: {
        work_experience_charge_prod: {}
      },
      knowledge_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Pas de connaissances' },
        { value: 2, text: 'Quelques connaissances' },
        { value: 3, text: 'Bonnes connaissances' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations: {
    workExperience: {
      work_experience_charge_prod: {
        gestion_projet_culturel: { required },
        gestion_projet_culturel_notes: { maxLength: maxLength(512) },
        production_vente_spectacle: { required },
        production_vente_spectacle_notes: { maxLength: maxLength(512) },
        organisation_concert_salle: { required },
        organisation_concert_salle_notes: { maxLength: maxLength(512) },
        organisation_event_exterieur: { required },
        organisation_event_exterieur_notes: { maxLength: maxLength(512) },
        edition_oeuvre_musique: { required },
        edition_oeuvre_musique_notes: { maxLength: maxLength(512) },
        prod_com_records: { required },
        prod_com_records_notes: { maxLength: maxLength(512) },
        management_artistic_project: { required },
        management_artistic_project_notes: { maxLength: maxLength(512) }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.work_experience)) {
      this.workExperience = this.modelData.work_experience
      if (_.isEmpty(this.workExperience.work_experience_charge_prod)) {
        this.workExperience.work_experience_charge_prod = {}
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp, subprop) {
      this.onContextChanged(myProp, subprop)
    }, 200),
    onContextChanged (prop, subprop) {
      if (this.$v.workExperience[prop][subprop]) {
        this.$v.workExperience[prop][subprop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'work_experience', 'property': prop, 'value': this.workExperience[prop] })
    },
    validate () {
      this.$v.workExperience.$touch()
      var isValid = !this.$v.workExperience.$invalid
      this.$emit('on-validate', this.$v.workExperience, isValid)
      return isValid
    }
  }
}
</script>
