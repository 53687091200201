<template>
  <b-card class="mt-4">
    <template v-slot:header>
      <a v-b-toggle.workExperienceTechPlateauForm>
        <h2 class="card-title mt-1 mb-1" slot="header">{{ $t('labels.backend.registrations.titles.work_experience.work_experience_techplat.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="workExperienceTechPlateauForm" role="tabpanel">
      <b-card-body>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.tech_docs')"
          label-for="tech_docs"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'tech_docs')"
            id="tech_docs"
            name="tech_docs"
            v-model="workExperience.work_experience_tech_plat.tech_docs"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.tech_docs')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.tech_docs.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.tech_docs.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.tech_docs_notes')"
          label-for="tech_docs_notes"
          :feedback="feedback('tech_docs_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.tech_docs_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.tech_docs > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="tech_docs_notes"
            name="tech_docs_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.tech_docs_notes')"
            v-model="workExperience.work_experience_tech_plat.tech_docs_notes"
            :state="state('tech_docs_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.tech_docs_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.tech_docs_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_sound')"
          label-for="regie_sound"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'regie_sound')"
            id="regie_sound"
            name="regie_sound"
            v-model="workExperience.work_experience_tech_plat.regie_sound"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_sound')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.regie_sound.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.regie_sound.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_sound_notes')"
          label-for="regie_sound_notes"
          :feedback="feedback('regie_sound_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.regie_sound_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.regie_sound > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="regie_sound_notes"
            name="regie_sound_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_sound_notes')"
            v-model="workExperience.work_experience_tech_plat.regie_sound_notes"
            :state="state('regie_sound_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.regie_sound_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.regie_sound_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_stage')"
          label-for="regie_stage"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'regie_stage')"
            id="regie_stage"
            name="regie_stage"
            v-model="workExperience.work_experience_tech_plat.regie_stage"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_stage')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.regie_stage.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.regie_stage.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_stage_notes')"
          label-for="regie_stage_notes"
          :feedback="feedback('regie_stage_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.regie_stage_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.regie_stage > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="regie_stage_notes"
            name="regie_stage_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.regie_stage_notes')"
            v-model="workExperience.work_experience_tech_plat.regie_stage_notes"
            :state="state('regie_stage_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.regie_stage_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.regie_stage_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.electric')"
          label-for="electric"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'electric')"
            id="electric"
            name="electric"
            v-model="workExperience.work_experience_tech_plat.electric"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.electric')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.electric.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.electric.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.electric_notes')"
          label-for="electric_notes"
          :feedback="feedback('electric_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.electric_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.electric > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="electric_notes"
            name="electric_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.electric_notes')"
            v-model="workExperience.work_experience_tech_plat.electric_notes"
            :state="state('electric_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.electric_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.electric_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.security')"
          label-for="security"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'security')"
            id="security"
            name="security"
            v-model="workExperience.work_experience_tech_plat.security"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.security')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.security.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.security.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.security_notes')"
          label-for="security_notes"
          :feedback="feedback('security_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.security_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.security > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="security_notes"
            name="security_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.security_notes')"
            v-model="workExperience.work_experience_tech_plat.security_notes"
            :state="state('security_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.security_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.security_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.computer')"
          label-for="computer"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'computer')"
            id="computer"
            name="computer"
            v-model="workExperience.work_experience_tech_plat.computer"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.computer')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.computer.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.computer.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.computer_notes')"
          label-for="computer_notes"
          :feedback="feedback('computer_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.computer_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.computer > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="computer_notes"
            name="computer_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.computer_notes')"
            v-model="workExperience.work_experience_tech_plat.computer_notes"
            :state="state('computer_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.computer_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.computer_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.music_prod_organization')"
          label-for="music_prod_organization"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'music_prod_organization')"
            id="music_prod_organization"
            name="music_prod_organization"
            v-model="workExperience.work_experience_tech_plat.music_prod_organization"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.music_prod_organization')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.music_prod_organization.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.music_prod_organization.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.music_prod_organization_notes')"
          label-for="music_prod_organization_notes"
          :feedback="feedback('music_prod_organization_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.music_prod_organization_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.music_prod_organization > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="music_prod_organization_notes"
            name="music_prod_organization_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.music_prod_organization_notes')"
            v-model="workExperience.work_experience_tech_plat.music_prod_organization_notes"
            :state="state('music_prod_organization_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.music_prod_organization_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.music_prod_organization_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.socio_professional')"
          label-for="socio_professional"
        >
          <b-form-select
            @change="onContextChanged('work_experience_tech_plat', 'socio_professional')"
            id="socio_professional"
            name="socio_professional"
            v-model="workExperience.work_experience_tech_plat.socio_professional"
            :options="knowledge_level_options"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.socio_professional')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.socio_professional.$error }"
          ></b-form-select>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.socio_professional.$error">
            Ce champs est requis
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('validation.attributes.registration.work_experience.work_experience_techplat.socio_professional_notes')"
          label-for="socio_professional_notes"
          :feedback="feedback('socio_professional_notes')"
          :description="textLimitCounter(workExperience.work_experience_tech_plat.socio_professional_notes, 512)"
          v-if="workExperience.work_experience_tech_plat.socio_professional > 1"
        >
          <b-form-input
            @input="debounceInput('work_experience_tech_plat')"
            @change="onContextChanged('work_experience_tech_plat')"
            id="socio_professional_notes"
            name="socio_professional_notes"
            :placeholder="$t('validation.attributes.registration.work_experience.work_experience_techplat.socio_professional_notes')"
            v-model="workExperience.work_experience_tech_plat.socio_professional_notes"
            :state="state('socio_professional_notes')"
            :class="{ 'is-invalid': $v.workExperience.work_experience_tech_plat.socio_professional_notes.$error }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!$v.workExperience.work_experience_tech_plat.socio_professional_notes.$error">
            512 caractères maximum
          </b-form-invalid-feedback>
        </b-form-group>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import { required, maxLength } from 'vuelidate/lib/validators'
import form from '../../mixins/form'
import _ from 'lodash'

export default {
  name: 'WorkExperienceTechPlatForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      workExperience: {
        work_experience_tech_plat: {}
      },
      knowledge_level_options: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 1, text: 'Pas de connaissances' },
        { value: 2, text: 'Quelques connaissances' },
        { value: 3, text: 'Bonnes connaissances' }
      ],
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altInputClass: 'flatpickr-alt-input form-control',
        altFormat: 'd/m/Y'
      }
    }
  },
  validations: {
    workExperience: {
      work_experience_tech_plat: {
        tech_docs: { required },
        tech_docs_notes: { maxLength: maxLength(512) },
        regie_sound: { required },
        regie_sound_notes: { maxLength: maxLength(512) },
        regie_stage: { required },
        regie_stage_notes: { maxLength: maxLength(512) },
        electric: { required },
        electric_notes: { maxLength: maxLength(512) },
        security: { required },
        security_notes: { maxLength: maxLength(512) },
        computer: { required },
        computer_notes: { maxLength: maxLength(512) },
        music_prod_organization: { required },
        music_prod_organization_notes: { maxLength: maxLength(512) },
        socio_professional: { required },
        socio_professional_notes: { maxLength: maxLength(512) }
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    if (!_.isEmpty(this.modelData.work_experience)) {
      this.workExperience = this.modelData.work_experience
      if (_.isEmpty(this.workExperience.work_experience_tech_plat)) {
        this.workExperience.work_experience_tech_plat = {}
      }
    }
  },
  methods: {
    debounceInput: _.debounce(function (myProp, subprop) {
      this.onContextChanged(myProp, subprop)
    }, 200),
    onContextChanged (prop, subprop) {
      if (this.$v.workExperience[prop][subprop]) {
        this.$v.workExperience[prop][subprop].$touch()
      }
      this.$emit('context-changed', { 'relation': 'work_experience', 'property': prop, 'value': this.workExperience[prop] })
    },
    validate () {
      this.$v.workExperience.$touch()
      var isValid = !this.$v.workExperience.$invalid
      this.$emit('on-validate', this.$v.workExperience, isValid)
      return isValid
    }
  }
}
</script>
