import 'babel-polyfill'
import loadClientScripts from './load-client-scripts'

// Vue & axios
import Vue from 'vue'
import { axios } from '../axios-config'

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import { SelfBuildingSquareSpinner } from 'epic-spinners'
import DateTimePicker from './components/Plugins/DateTimePicker'
import Vuelidate from 'vuelidate'

import RegistrationForm from './components/RegistrationForm'

import { createLocales } from '../vue-i18n-config'

window.axios = axios

// Bootstrap Vue
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.component('v-main-spinner', SelfBuildingSquareSpinner)
Vue.component('p-datetimepicker', DateTimePicker)
Vue.component('v-registration-form', RegistrationForm)

export function createApp () {
  const i18n = createLocales(window.locale)
  window.i18n = i18n

  /**
   * Server-side settings
   */
  Vue.prototype.$app = window.settings

  /**
   * Server-side named routes function router
   */
  Vue.prototype.$app.route = window.route

  /**
   * Object to FormData converter
   */
  let objectToFormData = (obj, form, namespace) => {
    let fd = form || new FormData()

    for (let property in obj) {
      if (!obj.hasOwnProperty(property)) {
        continue
      }

      let formKey = namespace ? `${namespace}[${property}]` : property

      if (obj[property] === null) {
        fd.append(formKey, '')
        continue
      }
      if (typeof obj[property] === 'boolean') {
        fd.append(formKey, obj[property] ? '1' : '0')
        continue
      }
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString())
        continue
      }
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
        continue
      }
      fd.append(formKey, obj[property])
    }
    return fd
  }

  Vue.prototype.$app.objectToFormData = objectToFormData

  const app = new Vue({
    i18n
  })

  return { app }
}

// Load Client Scripts
loadClientScripts(createApp)
