<template>
  <div>
    <b-card>
      <template v-slot:header>
        <a v-b-toggle.processWnd>
          <h2 class="card-title mb-1 mt-1" slot="header">Procédure de séléction</h2>
        </a>
      </template>
      <b-collapse visible id="processWnd" role="tabpanel">
        <b-card-body>
          <div class="row">
            <div class="col-12 mb-4" v-html="modelData.campaign.course.body">
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card class="mt-4">
      <template v-slot:header>
        <a v-b-toggle.cguWnd>
          <h2 class="card-title mb-1 mt-1" slot="header">Conditions d'utilisation</h2>
        </a>
      </template>
      <b-collapse visible id="cguWnd" role="tabpanel">
        <b-card-body>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="mb-5" v-html="modelData.campaign.course.terms"></div>
              <div class="mt-5">
                <p>Pour continuer veuillez accepter les conditions d'utilisations :</p>
                <button @click="acceptTerms()" class="btn btn-danger finish-button">J'accepte les conditions d'utilisations</button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card class="mt-4">
      <template v-slot:header>
        <a v-b-toggle.cguWnd>
          <h2 class="card-title mb-1 mt-1" slot="header">Espace Financeurs</h2>
        </a>
      </template>
      <b-collapse visible id="cguWnd" role="tabpanel">
        <b-card-body>
          <div class="row">
            <div class="col-12 mb-4" v-html="modelData.campaign.course.sponsors">
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>

import form from '../../mixins/form'

export default {
  name: 'CGUForm',
  mixins: [form],
  props: {
    modelData: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
  },
  methods: {
    acceptTerms () {
      this.$emit('context-changed', { 'relation': null, 'property': 'current_step', 'value': 1 })
      this.$emit('context-changed', { 'relation': null, 'property': 'status', 'value': 'started' })
    }
  }
}
</script>
