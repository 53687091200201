<template>
  <div>
    <div v-if="model_loaded && (model.campaign.status !== 3 || isAdmin)">
      <component-cgu-form v-show="model.current_step === 0" :model-data="model" @context-changed="formComponentChanged">
      </component-cgu-form>
      <form-wizard :title="'Votre inscription'" ref="wizard" :subtitle="model.campaign.course.title" :start-index="getCurrentStep()"
                   v-show="isAdmin || (model.current_step !== 7 && current_step !== 0)"
      >
        <tab-content title="État civil" :before-change="()=>nextStep(1)">
          <component-personal-data-form ref="step1" :model-data="model" @context-changed="formComponentChanged">
          </component-personal-data-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step1_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step1_footer"></b-card-text>
          </b-card>
        </tab-content>
        <tab-content title="Statut Professionnel" :before-change="()=>nextStep(2)">
          <component-pro-status-form ref="step2" :model-data="model" @context-changed="formComponentChanged">
          </component-pro-status-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step2_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step2_footer"></b-card-text>
          </b-card>
        </tab-content>
        <tab-content title="Formations" :before-change="()=>nextStep(3)">
          <component-education-form ref="step3" :model-data="model" @context-changed="formComponentChanged">
          </component-education-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step3_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step3_footer"></b-card-text>
          </b-card>
        </tab-content>
        <tab-content title="Expérience Professionnelle" :before-change="()=>nextStep(4)">
          <component-work-experience-form ref="step4-1" :model-data="model" @context-changed="formComponentChanged">
          </component-work-experience-form>
          <component-work-experience-chargeprod-form ref="step4-2" :model-data="model" @context-changed="formComponentChanged" v-if="model.campaign.course_id === 1">
          </component-work-experience-chargeprod-form>
          <component-work-experience-techplat-form ref="step4-3" :model-data="model" @context-changed="formComponentChanged" v-if="model.campaign.course_id === 2">
          </component-work-experience-techplat-form>
          <component-work-experience-regprod-form ref="step4-4" :model-data="model" @context-changed="formComponentChanged" v-if="model.campaign.course_id === 3">
          </component-work-experience-regprod-form>
          <component-work-experience-booker-form ref="step4-5" :model-data="model" @context-changed="formComponentChanged" v-if="model.campaign.course_id === 4">
          </component-work-experience-booker-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step4_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step4_footer"></b-card-text>
          </b-card>
        </tab-content>
        <tab-content title="Projet Professionnel" :before-change="()=>nextStep(5)">
          <component-professional-project-form ref="step5" :model-data="model" @context-changed="formComponentChanged">
          </component-professional-project-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step5_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step5_footer"></b-card-text>
          </b-card>
        </tab-content>
        <tab-content title="Annexes et paiement" :before-change="()=>nextStep(6)">
          <component-documents-form ref="step6-1" :model-data="model" @context-changed="formComponentChanged">
          </component-documents-form>
          <component-checkout-form ref="step6-2" :model-data="model" :stripe-key="stripeKey" @context-changed="formComponentChanged">
          </component-checkout-form>
          <b-card
            class="step-footer mt-4"
            border-variant="dark"
            header="Information"
            header-bg-variant="dark"
            header-text-variant="white"
            v-if="model.campaign.course.step6_footer"
          >
            <template v-slot:header>
              <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
            </template>
            <b-card-text v-html="model.campaign.course.step6_footer"></b-card-text>
          </b-card>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div v-if="current_step_invalid" class="alert alert-danger fade show" role="alert">
            <strong>Erreur :</strong> Veuillez renseigner ou corriger les champs marqués en rouge ci-dessus.
          </div>
          <div class="wizard-footer-left">
            <button v-if="props.activeTabIndex > 0" @click="props.prevTab()" class="btn btn-danger mt-4">Retour</button>
          </div>
          <div class="wizard-footer-right">
            <button v-if="props.activeTabIndex + 1 === model.current_step" @click="saveRegistration()" class="mr-4 btn btn-outline-secondary mt-4" :disabled="pending"><b-spinner v-if="pending" small class="mr-3"></b-spinner>{{ pending ? 'Enregistrement en cours': 'Enregistrer' }}</button>
            <button v-if="$refs.wizard && !props.isLastStep" @click="props.nextTab()" class="btn btn-danger mt-4"><b-spinner v-if="pending" small class="mr-3"></b-spinner>Passer à l'étape suivante</button>
            <button v-else @click="finishRegistration()" class="btn btn-danger finish-button mt-4">Terminer mon inscription</button>
          </div>
        </template>
      </form-wizard>
      <div v-show="current_step === 7">
        <component-extra-form ref="step7" :model-data="model" @context-changed="formComponentChanged"></component-extra-form>
        <b-card
          class="step-footer mt-4"
          border-variant="dark"
          header="Information"
          header-bg-variant="dark"
          header-text-variant="white"
          v-if="model.campaign.course.step7_footer"
        >
          <template v-slot:header>
            <h4 class="d-inline-block mb-0"><i class="fas fa-info-circle mr-3"></i>Information</h4>
          </template>
          <b-card-text v-html="model.campaign.course.step7_footer"></b-card-text>
        </b-card>
      </div>
    </div>
    <div v-if="model.campaign.status === 3 && !isAdmin">
      <b-card>
        <b-collapse id="finish" visible role="tabpanel">
          <b-card-body>
            <div class="row">
              <div class="col-12 text-center mb-4">
                <p>Cette campagne est terminée</p>
                <a :href="$app.route('user.home')">Retour à mon compte</a>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>

import animationData from '../../assets/animation2.js'
import form from '../../mixins/form'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import RegistrationCGUForm from './CGUForm.vue'
import RegistrationProfessionalStatusForm from './ProfessionalStatusForm.vue'
import RegistrationPersonalDataForm from './PersonalDataForm.vue'
import RegistrationEducationForm from './EducationForm.vue'
import RegistrationWorkExperienceForm from './WorkExperienceForm.vue'
import RegistrationWorkExperienceChargeProdForm from './WorkExperienceChargeProdForm.vue'
import RegistrationWorkExperienceTechPlatForm from './WorkExperienceTechPlatForm.vue'
import RegistrationWorkExperienceRegProdForm from './WorkExperienceRegProdForm.vue'
import RegistrationWorkExperienceBookerForm from './WorkExperienceBookerForm.vue'
import RegistrationProfessionalProjectForm from './ProfessionalProjectForm.vue'
import RegistrationDocumentsForm from './DocumentsForm.vue'
import RegistrationCheckoutForm from './CheckoutForm.vue'
import RegistrationExtraForm from './ExtraForm.vue'

export default {
  name: 'RegistrationForm',
  components: {
    FormWizard,
    TabContent,
    'component-cgu-form': RegistrationCGUForm,
    'component-pro-status-form': RegistrationProfessionalStatusForm,
    'component-personal-data-form': RegistrationPersonalDataForm,
    'component-education-form': RegistrationEducationForm,
    'component-work-experience-form': RegistrationWorkExperienceForm,
    'component-work-experience-chargeprod-form': RegistrationWorkExperienceChargeProdForm,
    'component-work-experience-techplat-form': RegistrationWorkExperienceTechPlatForm,
    'component-work-experience-regprod-form': RegistrationWorkExperienceRegProdForm,
    'component-work-experience-booker-form': RegistrationWorkExperienceBookerForm,
    'component-professional-project-form': RegistrationProfessionalProjectForm,
    'component-documents-form': RegistrationDocumentsForm,
    'component-checkout-form': RegistrationCheckoutForm,
    'component-extra-form': RegistrationExtraForm
  },
  mixins: [form],
  props: {
    id: {
      type: String,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    stripeKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      modelName: 'registration',
      resourceRoute: 'registrations',
      current_step: 0,
      current_step_invalid: false,
      model_updated: false,
      model_loaded: false,
      model: {
        id: null,
        current_step: 0,
        campaign_id: null,
        campaign: {
          course: {
            english: false,
            office_apps: false
          }
        },
        payment: false,
        registration_user: {},
        personal_data: {},
        professional_status: {},
        education_data: {},
        work_experience: {},
        professional_project: {},
        extra_form: {},
        thumbnail_id_photo_path: null,
        has_id_photo: false,
        id_photo: null,
        project_file: null,
        project_file_name: null,
        has_project_file: false,
        cv_file: null,
        cv_file_name: null,
        has_cv_file: false,
        motivation_letter_file: null,
        motivation_letter_file_name: null,
        has_motivation_letter_file: false,
        diploma_file: null,
        diploma_file_name: null,
        has_diploma_file: false,
        references_file: null,
        references_file_name: null,
        has_references_file: false,
        extras_file: null,
        extras_file_name: null,
        has_extras_file: false,
        list_documents: null,
        status: 'started'
      },
      defaultOptions: { animationData: animationData, loop: false },
      animationSpeed: 1
    }
  },
  mounted () {
  },
  methods: {
    getCurrentStep () {
      if (!this.isAdmin) {
        if (this.model.current_step > 0) {
          return this.model.current_step - 1
        } else {
          return 0
        }
      } else {
        if (this.model.current_step > 6) {
          return 5
        }
        if (this.model.current_step > 0) {
          return this.model.current_step - 1
        } else {
          return 0
        }
      }
    },
    onModelChanged () {
      this.model_loaded = true
      if (!this.isAdmin) {
        this.current_step = this.model.current_step
      } else {
        if (this.model.current_step > 6) {
          this.current_step = 5
        } else {
          this.current_step = this.model.current_step
        }
      }
      if (this.model.status === 2) {
        this.model.status = 'started'
      }
      if (this.model.status === 3) {
        this.model.status = 'finished'
      }
      this.$nextTick(() => {
        this.checkTabs()
      })
    },
    formComponentChanged (fieldData) {
      if (!fieldData.relation) { // Update registration
        this.model[fieldData.property] = fieldData.value
        if (fieldData.property === 'current_step') {
          this.current_step = fieldData.value
        }
        this.model_updated = true
      } else { // Update registration relation
        if (!this.model[fieldData.relation]) {
          this.model[fieldData.relation] = {}
        }
        this.model[fieldData.relation][fieldData.property] = fieldData.value
        this.model_updated = true
      }
      this.current_step_invalid = false
    },
    async finishRegistration () {
      this.model_updated = true
      let testValidation = this.finalValidation()
      if (testValidation) {
        let result = await window.swal({
          title: this.$t('labels.are_you_sure'),
          text: 'Souhaitez-vous terminer votre inscription ?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('buttons.cancel'),
          confirmButtonColor: '#dd4b39',
          confirmButtonText: this.$t('buttons.confirm')
        })

        if (result.value) {
          this.model.status = 'finished'
          this.pending = true
          await this.onSubmit()
          this.model.status = 3
          this.current_step = 7
          this.model.current_step = 7
          this.pending = false
          this.model_updated = false
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    validateStep (name) {
      var refToValidate = this.$refs[name]
      return refToValidate.validate()
    },
    async saveRegistration () {
      if (this.model_updated) {
        this.pending = true
        this.model.current_step = this.current_step
        await this.onSubmit()
        this.pending = false
        this.model_updated = false
      }
    },
    nextStep (step) {
      let saveModel = false
      if (this.model_updated) {
        saveModel = true
      }
      if (this.$refs.wizard.activeTabIndex + 1 >= this.model.current_step) {
        saveModel = true
      }
      if (step === 4) {
        let isValidStep = true
        if (!this.validateStep('step' + step + '-1')) {
          isValidStep = false
        }
        if (this.model.campaign.course_id === 1) {
          if (!this.validateStep('step' + step + '-2')) {
            isValidStep = false
          }
        }
        if (this.model.campaign.course_id === 2) {
          if (!this.validateStep('step' + step + '-3')) {
            isValidStep = false
          }
        }
        if (this.model.campaign.course_id === 3) {
          if (!this.validateStep('step' + step + '-4')) {
            isValidStep = false
          }
        }
        if (this.model.campaign.course_id === 4) {
          if (!this.validateStep('step' + step + '-5')) {
            isValidStep = false
          }
        }
        if (isValidStep) {
          if (step + 1 > this.model.current_step) {
            this.current_step = step + 1
          }
          if (this.model_updated && saveModel) {
            this.model.current_step = this.current_step
            this.onSubmit()
            this.model_updated = false
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return true
        } else {
          this.current_step_invalid = true
          return false
        }
      } else if (step === 6) {
        let isValidStep = true
        if (!this.validateStep('step' + step + '-1')) {
          isValidStep = false
        }
        if (!this.validateStep('step' + step + '-2')) {
          isValidStep = false
        }
        if (isValidStep) {
          if (step + 1 > this.model.current_step) {
            this.current_step = step + 1
          }
          if (this.model_updated && saveModel) {
            this.model.current_step = this.current_step
            this.onSubmit()
            this.model_updated = false
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return true
        } else {
          this.current_step_invalid = true
          return false
        }
      } else {
        let sname = 'step' + step
        if (this.validateStep(sname)) {
          if (step + 1 > this.model.current_step) {
            this.current_step = step + 1
          }
          if (this.model_updated && saveModel) {
            this.model.current_step = this.current_step
            this.onSubmit()
            this.model_updated = false
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return true
        } else {
          this.current_step_invalid = true
          return false
        }
      }
    },
    finalValidation () {
      let step = 6
      let isValidStep = true
      if (!this.validateStep('step' + step + '-1')) {
        isValidStep = false
      }
      if (!this.validateStep('step' + step + '-2')) {
        isValidStep = false
      }
      if (isValidStep) {
        return true
      } else {
        this.current_step_invalid = true
        return false
      }
    },
    checkTabs () {
      if (this.$refs.wizard) {
        for (let i = 0; i < this.$refs.wizard.startIndex; i++) {
          this.$refs.wizard.tabs[i].checked = true
        }
      }
    }
  }
}
</script>
